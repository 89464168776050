//
// Shadows
//

// General styles
[class*="shadow"] {
  @if $enable-transitions {
    transition: $transition-base;
  }
}

// Size variations
.shadow-sm--hover:hover {
  box-shadow: $box-shadow-sm !important;
}

.shadow--hover:hover {
  box-shadow: $box-shadow !important;
}

.shadow-lg--hover:hover {
  box-shadow: $box-shadow-lg !important;
}

.shadow-none--hover:hover {
  box-shadow: none !important;
}
.shadow {
  // border: none !important;
}
.card_with_paginator {
  border: none !important;
  th {
    border-top: none;
    // border-left: 1px solid #f5f5f5 !important;
    // border-right: 1px solid #f5f5f5 !important;
    border-bottom: none !important;
  }
  td {
    // border-top: none;
    // border-bottom: 1px solid #f5f5f5;
  }
  tbody {
    // border-left: 1px solid #f5f5f5;
    // border-right: 1px solid #f5f5f5;
  }
}
