.mt-9 {
  margin-top: 9rem !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.mb_20 {
  margin-bottom: 20px !important;
}
.vender {
  position: relative;
  transition: all 0.5s all;
}
.vender_side_bar {
  width: 35vw !important;
  overflow: scroll;
}
.vender_side_bar::-webkit-scrollbar {
  display: none;
}
.info {
  font-size: 10px;
  font-style: italic;
  color: rgb(119, 116, 116);
}
.vender_content {
  padding: 20px;
}
.align_top {
  margin-top: 7rem;
}
.input_holder_div {
  margin: 2rem 0;
}
.input_div {
  padding-left: 0px;
}
.chip_total_div {
  width: 100%;
  margin-bottom: 20px;
}
.number_input_holder {
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  padding-bottom: 10px;
}
.transparent_input {
  border: none;
  // margin-bottom: 15px;
}
.chips_div {
  display: flex;
  flex-wrap: wrap;
}
.individual_chip {
  background: #ffffff;
  border: 1px solid #757575;
  border-radius: 50px;
  padding: 4px 20px;
  color: #757575;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  margin-bottom: 15px;

  p {
    margin-bottom: 0px;
    margin-right: 15px;
  }
  i {
    color: #757575;
  }
}
.d_flex_space_align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
// new dashboard designs
.big_graph_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 500px;
}
.left_graph {
  background: #ffffff;
  // border: 1px solid #fafafa;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  width: 100%;
  position: relative;
  padding: 20px;
  height: 100%;
}
.right_graph {
  width: 33%;
  border: 1px solid #fafafa;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 100%;
}
.dashboard_card {
  border: none;
  width: 100%;
  min-height: 100%;
  padding: 0px;
  .card-header {
    background: white;
    border-bottom: 1px solid rgba(225, 225, 225, 0.4);
    padding-left: 20px !important;
    font-weight: 600;
    color: #212529;
  }
}
.pie_chart {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}
.display_pie_value {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  color: #374957;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h3 {
    font-weight: 700;
    font-size: 32px;
  }
  p {
    font-size: 0.688rem;
    font-weight: 400;
  }
}
.pie_label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px 5px 26px;
}
.individual_label {
  width: 32%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    font-weight: 600;
    font-size: 13px;
  }
}
.label_div {
  display: flex;
  align-items: center;
  h3 {
    margin: 0px;
    margin-left: 10px;
    color: #000000;
    font-weight: 400;
    font-size: 13px;
  }
}
.label_div h3:first-child {
  margin-left: 0px !important;
}
.booked_color_div {
  background: #405188;
  border-radius: 1px;
  width: 9px;
  height: 9px;
}
.vaccant_color_div {
  background: #ffbe0a;
  border-radius: 1;
  width: 9px;
  height: 9px;
}
.listing_big_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 360px;
  margin-top: 1.8rem;
}
.listing_left_container {
  width: 100%;
  height: 100%;
  min-height: 437px;
  max-height: 437px;
  border-radius: 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  // overflow-y: scroll;
  background-color: #f9f9f9;
  // border: 1px solid #fafafa;
}
.listing_left_container::-webkit-scrollbar {
  display: none;
}
.listing_right_container::-webkit-scrollbar {
  display: none;
}
.listing_right_container {
  width: 100%;
  height: 100%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  border: 1px solid #fafafa;
  overflow-y: scroll;
}
.booking_card {
  padding-left: 0px !important;
  border: none !important;
  padding-top: 0px;
}
.header_with_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin-bottom: 0px;
    font-size: 14px;
    color: #374957;
  }
  button {
    border: 1px solid rgba(225, 225, 225, 0.4);
    border-radius: 5px;
    outline: none;
    background: white;
    padding: 5px 10px;
    color: #374957;
    font-size: 0.875rem;
  }
}
.invisible_button {
  border-radius: 5px;
  outline: none;
  background: white;
  padding: 5px 10px;
  height: 33px;
  border: none !important;
  pointer-events: none;
}
.grey_header {
  background: #f5f5f5;
  th:first-child {
    padding-left: 20px;
  }
  th {
    padding: 15px 15px 15px 0px;
    font-size: 14px;
    font-weight: 600;
    color: #374957;
  }
}

.grey_data {
  td {
    padding: 15px 0px;
    // border-bottom: 16px solid rgba(225, 225, 225, 0.4);
    border-bottom: 16px solid #f9f9f9;
    font-weight: 400;
    font-size: 14px;
    color: #212529;
  }
  .first_td {
    padding-left: 20px;
  }
}
.priority_common_div {
  border-radius: 2px;
  padding: 5px 7px;
  height: 18px;
  font-weight: 500;
  font-size: 12px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.low_priority {
  color: #0ab39c;
  background: rgba(10, 179, 156, 0.1);
}
.high_priority {
  background: rgba(240, 101, 72, 0.1);
  color: #f06548;
}
.medium_priority {
  color: #f7b84a;
  background: rgba(247, 184, 74, 0.1);
}
.justify_card {
  justify-content: space-between;
}
.filters_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.left_filter_container {
  width: 80%;
  display: flex;
}

.right_filter_container {
  // width: 25%;
  display: flex;
  justify-content: flex-end;
  button {
    height: 100% !important;
  }
}

.invoice_left_filter_container {
  width: 70%;
  display: flex;
}

.invoice_right_filter_container {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}
.fixed_select {
  width: 131px;
  font-size: 14px !important;
}
.select_with_input {
  border: 1px solid rgba(225, 225, 225, 0.4);
  border-radius: 5px;
  display: flex;
  width: 30%;
  .select_input_drop {
    min-width: fit-content !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    padding: 0px 10px 0px 0px;
  }

  input {
    height: 100%;
    border: none;
    font-size: 0.813rem !important;
  }
  img {
    color: #374957;
  }
}
.shadow_div {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
}
.image_array_holder {
  .remove_image_icon {
    top: 2% !important;
  }
}
.no_data_found {
  padding-left: 32px;
  padding: 10px;
}
.status_select_input {
  .status_select_div {
    width: 30%;
  }
  select {
    background: #f5f5f5 !important;
  }
  .media {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
  }
  .btn-secondary {
    color: #374957;
    font-size: 0.813rem !important;
    font-weight: 400 !important;
    background: #f5f5f5 !important;
    border: none;
    border-radius: 0px;
  }
  .btn-secondary:hover,
  .btn-secondary:active {
    box-shadow: none;
  }
  .btn-secondary:focus {
    box-shadow: none !important;
  }
  .status_select_div:hover,
  status_select_div:active {
    box-shadow: none;
  }
}

.status_select {
  position: relative;
  width: 15%;
  margin-left: 10px;
  select {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(225, 225, 225, 0.4);
    border-radius: 5px;
    background: white !important;
    color: #374957;
    font-size: 0.813rem !important;
  }
  .status_select_div {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(225, 225, 225, 0.4);
    border-radius: 5px;
    background: white !important;
    color: #374957;
    font-size: 0.813rem !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    .btn-secondary {
      color: #374957;
      font-size: 0.813rem !important;
      font-weight: 400 !important;
      border: none;
    }
    .btn-secondary:hover,
    .btn-secondary:active {
      background: white !important;
      box-shadow: none;
    }
    .btn-secondary:focus {
      box-shadow: none !important;
    }
  }
  .status_select_div:hover,
  status_select_div:active {
    box-shadow: none;
  }
  .mark_payment_select {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(225, 225, 225, 0.4);
    border-radius: 5px;
    background: white !important;
    color: #374957;
    font-size: 0.813rem !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }
  .drop_down_custom_div {
    position: absolute;
    height: fit-content;
    width: 100%;
    top: 100%;
    left: 0px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    z-index: 99;
    background: white;
    padding: 10px;
  }
  .dropdown_options {
    font-size: 14px;
    padding-bottom: 12px;
    border-bottom: 1px solid #f5f5f5;
    cursor: pointer;
    margin: 0px;
    padding-top: 12px;
  }
  .dropdown_options:last-child {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
  }

  i {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.mt_20 {
  margin-top: 2rem !important;
}
.general_status_style {
  font-weight: 600 !important;
  font-size: 13px !important;
}
.graph_select_component {
  border: 1px solid rgba(225, 225, 225, 0.4);
  border-radius: 5px;
  color: #787486;
  font-size: 0.813rem;
  background: transparent;
  padding: 10px;
}
.graph_filter_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin-bottom: 0px;
    color: #012a4d;
    font-size: 15px;
    font-weight: 600;
    padding-left: 15px;
  }
}
.count_with_heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  p {
    margin-bottom: 0px;
    color: #899aa7;
    font-weight: 500;
    font-size: 13px;
  }
}
.count_div {
  color: #012a4d;
  font-weight: 600;
  font-size: 12px;
  // background: rgba(44, 182, 125, 0.1);
  background-color: #e5e9ed;
  border-radius: 3px;
  padding: 4px 16px;
  margin-right: 15px;
}
.invoice_dash {
  height: 150px !important;
}
.percentage_calulate {
  img {
    margin-right: 10px;
  }
}
.dash_right_invoice {
  width: inherit !important;
  height: inherit !important;
  align-items: flex-end !important;
  img {
    width: inherit !important;
    height: inherit !important;
  }
}
.dash_right_container {
  height: inherit !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.dash_content_invoice {
  align-items: unset !important;
}
.invoice_badge_increased {
  font-weight: 600;
  font-size: 14px;
  color: #47b275;
}

.invoice_badge_decreased {
  color: #ed5d51;
  font-weight: 600;
  font-size: 14px;
}
.modal-title {
  font-size: 16px;
}
.no_data_dashboard {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.add_property_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.dotted_document_div {
  width: 100%;
  padding: 35px;
  border: 1px dashed #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.image_spinner {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner-border {
    color: #012a4d;
  }
}
.uploaded_image_div {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}
.image_relative {
  position: relative;
  .pdf-remove {
    right: 0px;
  }
}
.card-header {
  box-shadow: unset !important;
}
