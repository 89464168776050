//
// Card stats
//

.card-stats {
  // box-shadow: none;
  .card-body {
    padding: 1rem 1.5rem;
  }

  .card-status-bullet {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
}
