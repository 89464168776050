@import "./variables";
@import "./mixins";

@font-face {
  font-family: "Poppins", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");
}

.pad1624 {
  padding: 16px 24px !important;
}

:root {
  --width: 700px;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 16px;
}

// body::-webkit-scrollbar {
//   display: none;
// }
// .profile_body {
//   overflow-y: auto !important;
//   max-height: 100vh !important;
// }

.sideBar_icon {
  width: 19px;
  margin-right: 1rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.side_bar_logo {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-bottom: 1.6rem;
  margin-bottom: 0.2rem;
  margin-top: 1.8rem;
  // border-bottom: 0.7px solid #4d5362;
  margin-left: 1.8rem;

  // height: 100%;
  // img {
  //   // width: 185px;
  //   // height: 28px;
  // }
}

.borderbtm0 {
  border-bottom: 0px !important;
}

.justLeft {
  display: flex;
  justify-content: left;
  align-items: center;
}

h3,
h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.bg-default {
  background-color: #edf0f8 !important;
}

.bg-transparent {
  text-align: center;
  padding: 1.7rem 1.7rem;
}

.bg-secondary .card-header {
  background: #fff !important;
}

.bgFAFAFA {
  background: #fafafa;
}

.bgE3E3E3 {
  background: #e3e3e3;
}

.bgFFFFFF {
  background: #ffffff;
}

.fl-end {
  justify-content: flex-end !important;
}

.logo-image {
  width: 44%;
}

.empty-card {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bg-secondary {
  background-color: white !important;
}

.nav_bar_right_side {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.switch_user {
  background: #ededed;
  border-radius: 45px;
  width: 180px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  margin-right: 30px;
}

.active_switch {
  background: #ffffff;
  color: #012a4d;
  // color: #012A4D;
  font-weight: 500;
}

.switch_common {
  width: 83px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 45px;
  cursor: pointer;
  font-size: 0.875rem;
}

.notification_bell {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100% !important;
    height: 100% !important;
  }
}

.notify_badge {
  position: absolute;
  width: 19px;
  height: 19px;
  background: #ed5d51;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0%;
  right: 0%;
  transform: translate(50%, -50%);
}

.inactive_switch {
  color: #899aa7;
}

.navBar:after {
  display: none !important;
}

.navBar {
  padding-bottom: 0px !important;
}

.notification-para {
  font-size: 15px;
  color: #515151;
  font-weight: 500;
}

.notification-table-pad .table td {
  padding: 1.2rem 1.5rem 1.2rem 3rem !important;
}

.new-profile-btn {
  font-size: 15px !important;
}

.input-group-alternative {
  border: 1px solid #edf0f8 !important;
}

.text-align {
  text-align: right;
}

.text-light {
  color: #5e72e4 !important;
}

a.text-light:hover {
  color: #5e72e4 !important;
}

small {
  font-size: 11px !important;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}

// Tool Tip
.__react_component_tooltip {
  background: white !important;
  color: $secondayColor !important;
  border: 1px solid #2121213b !important;
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  opacity: 1 !important;
  padding: 10px !important;
}

.__react_component_tooltip::after {
  // border-right-color: #2121213b !important;
  // border-top-color: #2121213b !important;
  // border-left-color: #2121213b !important;
  // border-left-color: #2121213b !important;
  border-color: #2121213b !important;
  background-color: #fff !important;
}

.info-text {
  margin-top: 2rem;
  color: $secondayColor;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.toggle-link {
  text-align: center;
  margin-top: 0.3rem;
  font-size: 13px;

  font-family: "Poppins", sans-serif;

  span {
    color: $primaryColor;
    cursor: pointer;
    padding-left: 0.1rem;
    padding-right: 0.2rem;
    font-family: "Poppins", sans-serif;
  }
}

.new-max-width {
  max-width: 780px !important;
}

.pad-log-bot {
  padding-top: 0.7rem !important;
  padding-bottom: 2.5rem !important;
}

.border-0 {
  border-bottom: none;
}

.pad-card-top {
  padding: 2.5rem 1.4rem 1rem !important;
}

.new-pro-design {
  height: 100vh;
  @include flexCenter(center, center);
}

.pad-but {
  padding: 10px 19px !important;
  font-size: 1.325rem !important;
}

.pad-new-in {
  padding: 10px !important;
}

.pad-new-invite {
  padding: 0.4rem 1.5rem 0.4rem;
  color: rgba(0, 0, 0, 0.5);
}

.sign-auth {
  width: 100%;
  font-size: 1.125rem !important;
}

.radius-log {
  border-radius: 4px;
}

.input-group .form-control:not(:first-child) {
  border-left: 0;
  padding-left: 12px;
}

.navbar-vertical .navbar-nav .nav-link > i {
  min-width: 3rem;
  font-size: 1.5375rem;
}

// Reset Password
.verify-email-max-width {
  max-width: 955px !important;
}

.label-verify {
  color: #555555 !important;
  font-family: "Poppins", sans-serif;
  font-weight: 300 !important;
  font-size: 17px;
  text-align: center;
  line-height: 1.9rem;
}

.verify-flex {
  @include flexCenter(center, center);
  flex-direction: column;
}

.verify-text {
  color: #555555;
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.radius-log-verify {
  border-radius: 11px;
}

.success-verify-max-width {
  max-width: 836px !important;
}

.pad-log-bot-verify {
  padding: unset !important;
}

.foot-verify {
  padding: 1.2rem 1.5rem !important;
}

.disable-span {
  color: #3d3c3c75 !important;
  font-weight: 400 !important;

  font-family: "Poppins", sans-serif;
  cursor: not-allowed !important;
}

.receive-email {
  color: #555555;
  font-weight: 400;
  font-family: "Poppins", sans-serif;

  span {
    color: $primaryColor;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-left: 0.2rem;
    cursor: pointer;
  }
}

.margin-level-verify {
  margin-bottom: 1.1rem !important;
}

.navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
  padding: 1rem 1.2rem !important;
  font-size: 1rem;
}

.navbar-vertical::-webkit-scrollbar {
  overflow-y: auto;
  opacity: 0;
}

.navbar-vertical .navbar-nav .nav-link > i {
  min-width: 3rem;
  font-size: 1.2375rem;
}

.image-bot-div {
  border-bottom: 1px solid #00000033;
}

.bg-gradient-info {
  background: linear-gradient(
    87deg,
    #077e8b70 0,
    $primaryColor 100%
  ) !important;
}

.nav-link i.ni {
  padding-bottom: 0.3rem;
}

.host-name {
  font-size: 1.2rem !important;
}

.cursor-point {
  cursor: pointer;
}

.cursor-disable {
  cursor: not-allowed;
}

.table td,
.table th {
  font-size: 0.9125rem;
}

.table thead th {
  font-size: 0.9125rem;
}

h3,
.h3 {
  font-size: 1.3625rem;
}

.pagination {
  justify-content: flex-end !important;
}

.col-xl-8 {
  flex: unset;
  max-width: unset;
}

input[type="checkbox"] {
  cursor: pointer;
}

.word-break {
  white-space: break-spaces;
}

input[type="checkbox"]:after {
  content: " ";
  background-color: $primaryColor;
  color: white;
  visibility: visible;
}

input[type="checkbox"]:checked:after {
  content: "\2714";
  width: 100%;
  height: 100%;
  @include flexCenter(center, center);
  padding: 9px;
  border-radius: 3px;
}

.bg-gradient-default {
  background: linear-gradient(
    87deg,
    #077e8bd1 0,
    $primaryColor 100%
  ) !important;
}

.btn-info {
  color: #fff !important;
  background-color: $primaryColor !important;
  border: none !important;
  margin-left: 0px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.btn-info:hover {
  background-color: #0a3f6b !important;
}

.btn-info-inactive {
  color: #757575 !important;
  background-color: #f3f3f3 !important;
  border: none !important;
  margin-left: 0px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  box-shadow: 1px 1px 2px 0.3px #dbdbdb !important;
}

.btn-info-inactive:hover {
  background-color: #f3f3f3 !important;
}

.align_center {
  display: flex;
  align-items: center;
}

.d_flex {
  display: flex;
}

.bgf9f9f9 {
  background: #f9f9f9 !important;
}

.bgF3F3F9 {
  background: #f3f3f9 !important;
}

h5,
.h5 {
  font-size: 1.2125rem;
}

h3 {
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.close > span:not(.sr-only) {
  font-size: 2.3rem;
  height: 0rem !important;
}

.pad_new_col {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.font-high {
  font-size: 0.9rem;
}

.row-center {
  justify-content: space-between;
}

.mt-4_5 {
  margin-top: 2.3rem !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.dash-col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0px;
}

.dash_left {
  width: 70%;
}

.dash_right {
  width: 49px;
  height: 49px;
}

.heading_with_badge {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.heading_badge {
  i {
    font-size: 0.6rem;
    margin-right: 6px;
  }

  height: 24px;
  background: rgba(240, 101, 72, 0.1);
  border-radius: 3px;
  padding: 2px 4px;
  color: #f06548;
  font-size: 0.625rem !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading_badge_success {
  i {
    font-size: 0.6rem;
    margin-right: 6px;
  }

  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.625rem !important;
  font-weight: 600;
  border-radius: 3px;
  padding: 2px 4px;
  color: #2cb67d;
  background: rgba(44, 182, 125, 0.1);
}

.pad-dash {
  padding: 1.25rem !important;
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  h4 {
    font-size: 0.875rem !important;
    color: #899aa7;
    font-weight: 600 !important;
    margin-bottom: 0px;
    margin-right: 10px;
  }
}

.dash-new {
  border-radius: 5px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1) !important;
}

.dash_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.row-dash {
  width: 100%;
  justify-content: space-between;
  margin-top: 2.2rem;
  padding: 0 0px 0 22px;
}

.row-dash h4 {
  font-size: $secondayFontSize;
  color: $tertiaryColor;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.dashi {
  color: black;
  font-size: 1.625rem;
  padding-left: 3rem;
}

.fa-greater-than {
  margin-top: 0.5rem;
  font-size: $primaryFontSize;
  color: #e1e1e1;
}

.img-dash {
  width: 100%;
  height: 100%;
}

.dropdown_item {
  img {
    width: 17px !important;
    margin-right: 1rem;
  }
}

.max-dash {
  // max-width: 25%;
  transition: 1s;
  cursor: pointer;
  // padding-left: 10px;
}

// .max-dash:first-child {
//   padding-left: 15px !important;
// }
.max-dash:hover {
  transform: translateY(-5%);
}

.number-con {
  font-weight: 600;
  margin-bottom: 0;
  font-size: 25px;
  line-height: inherit;
  width: 100%;

  span {
    color: #374957 !important;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
  }
}

.main-content {
  position: unset;
}

// Invoice
.modal-dialog-invoice {
  max-width: 800px;
}

.invoice-title {
  font-size: 35px;
}

.invoice-park {
  width: 13rem;
}

.invoice-header {
  padding: 2.3rem 0rem 2.3rem 0rem !important;
}

.label-invoice {
  margin-top: 1.7rem !important;
  margin-bottom: 1.2rem !important;
}

.label-payment {
  margin-top: 1.7rem !important;
}

.prop-detail-para {
  font-size: 17px;
  color: #22212f;
  font-weight: 400;

  font-family: "Poppins", sans-serif;
}

.invoice-body {
  border-bottom: 2px solid #e8e3e373;
  margin: 0 2.5rem 0 2.5rem;
  padding: 0 !important;
}

.label-invoice-prop {
  font-size: 19px !important;
  color: #928f8f !important;
  font-weight: 400 !important;

  font-family: "Poppins", sans-serif;
}

.label-invoice-id {
  font-size: 19px !important;
  color: #393939;
  font-weight: 400 !important;

  font-family: "Poppins", sans-serif;
}

.label-invoice-name {
  font-size: 19px !important;
  color: #22212f;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif;
}

.flex-secondary-invoice {
  margin-bottom: 0 !important;
}

.primary-color {
  color: $primaryColor !important;
}

.nav-last-panel {
  margin-bottom: 2.3rem !important;
}

.flex-invoice {
  @include flexCenter(space-between, baseline);
}

.bottom-line-invoice {
  margin: 0 2.5rem 0 2.5rem;
  border-bottom: 2px solid #e8e3e373;
}

.main-content .navbar-top {
  position: fixed;
  z-index: 1;
  background-color: #fff !important;
  padding: 14px 29px !important;
  width: inherit;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 4px 5px 0px;
  border-bottom: 1px solid rgb(225, 232, 240);
}

.host-name {
  color: black;
}

.fa-hamburger {
  margin-left: 2rem;
  font-size: 20px;
}

.max-width-rules {
  max-width: 15.33333% !important;
  margin-bottom: 1rem;
}

.table thead th {
  text-transform: none;
}

.border-trans {
  border: none;
  background: transparent;
  color: #5e5e5e !important;
  font-weight: 600;
  width: 185px;
  cursor: pointer;
}

.default-amenity {
  width: 2rem;
}

.mt-ameni {
  margin-top: 0.9rem !important;
}

.opac-hide {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  opacity: 0;
}

.image-hide {
  position: relative;
}

.arrayImg {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.pdfImage {
  position: relative;
}

.billImage {
  width: 80%;
  height: 88%;
}

.card-pad-wrapper {
  padding: 0 29px 0 29px !important;
}

.toastContainer {
  z-index: 99;
}

.ni-fat-remove {
  position: absolute;
  font-size: 2.3rem;
  top: 21%;
  right: 0%;
  cursor: pointer;
}

.purchase-i-remove {
  top: 2px !important;
  right: 10px !important;
}

.purchase-remove {
  top: 9% !important;
}

.pdf-remove {
  position: absolute;
  font-size: 2.3rem;
  right: unset;
  top: unset;
  cursor: pointer;
}

.gallery-change-pad {
  padding-top: 1rem !important;
  min-height: 10vh;
}

.navbar-vertical.navbar-expand-md.fixed-left {
  z-index: 1;
}

.navbar-top img {
  width: 13%;
}

.navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
  padding: 0.9rem 1.3rem;
  font-size: 24px;
}

.mt-6 {
  margin-top: 6rem !important;
}

.mt-7r {
  margin-top: 7rem !important;
}

.avatar-sm {
  width: 42px !important;
  height: 42px !important;
  font-size: 0.875rem;
  object-fit: cover;
}

.icon_side {
  margin-right: 1rem;
}

.navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
  padding: 1.4rem 3rem;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.side_name {
  margin: 0;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 13px;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  text-transform: capitalize;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $primaryColor;
  background-color: $primaryColor;
}

.green {
  color: #008000 !important;
}

.button_spinner {
  .spinner-border {
    color: #dfeced !important;
  }

  .spinner-border {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
}

.disable_button {
  pointer-events: none;
}

.disable_view {
  cursor: not-allowed;
  color: rgb(167, 163, 163);
}

.select_customized_div {
  label {
    margin-bottom: 14px;
  }

  display: flex;
  flex-direction: column !important;

  .select_customer {
    width: 400px;
    border-bottom: 1px solid #e1e1e1;
    background: transparent;
    box-shadow: none;
  }

  button {
    box-shadow: none !important;
    background: white !important;
    border: none !important;
    padding-left: 0px;
    font-weight: 400;
  }

  .full_width_options {
    width: 100%;
  }
}

.pl_40 {
  padding-left: 40px !important;
}

.red {
  color: #f83245 !important;
}

.yellow {
  color: #ffc043 !important;
}

.blue {
  color: rgb(52, 152, 251) !important;
}

.primary {
  color: $primaryColor !important;
}

.change-notice-pass {
  min-width: 63px !important;
  padding: 5px 6px !important;
}

.accept-notice {
  margin-top: unset !important;
}

.data-wrap {
  display: flex;
}

.change-notice-pass label {
  font-size: 14px !important;
}

.accept-wrap {
  color: #008000 !important;
  border: 1px solid #008000 !important;
}

.reject-wrap {
  color: #f83245 !important;
  border: 1px solid #f83245 !important;
}

.custom-switch .custom-control-label::before,
.custom-switch .custom-control-label::after {
  cursor: pointer;
}

.custom-switch {
  z-index: auto;
}

.pad-to-8 {
  padding-top: 0.8rem;
}

.navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
  border-left: none !important;
}

.navbar-vertical {
  // background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.57), #f5f5f5);
  // background-image: linear-gradient(
  //   to right,
  //   rgba(255, 255, 255, 0.57) 1.33%,
  //   #f5f5f5
  // );
  // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
  background: #012a4d !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.nav-active {
  // border-left: 5px solid white !important;
  background: white;
  width: 100%;
  // border-top-right-radius: 4px;
  // border-bottom-right-radius: 4px;
}

.nav-active .side_name {
  color: $primaryColor;
  font-weight: 500 !important;
}

.nav-inactive {
  // background: transparent;
  border-left: none;
  width: 100%;
}

.nav-inactive:hover {
  background-color: #02437c;
}

.hoverColor:hover {
  background-color: #02437c;
}

.navbar-vertical .navbar-nav .nav-link.active > .side_name {
  font-weight: 500;
  // color: $primaryColor;
  font-family: "Poppins", sans-serif;
}

.side_dark {
  font-weight: 600;
  color: $primaryColor;
}

.side_bar_data {
  margin-bottom: 10px;

  .nav-link {
    height: 50px;
  }

  width: 100%;
  padding: 0rem;
}

.container_box {
  display: flex !important;
  padding: 0px !important;

  .toogle_side_bar {
    img {
      cursor: pointer;
      width: 100%;
      height: 100%;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.lh-1 {
  line-height: 1 !important;
}

.lh-unset {
  line-height: unset !important;
}

.no_side_bar_data {
  display: none;
}

.small_side_bar {
  .navbar-vertical {
    width: 4% !important;
    padding-right: 0px;
  }

  .icon_side {
    margin-right: 0px;
  }

  .main-content {
    width: 96% !important;
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .no_side_bar_data {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    flex-direction: column;
    margin: 0px;

    .nav-link {
      padding: 1.4rem 0rem !important;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0px !important;
    }

    .navbar-nav li {
      width: 100%;
      margin-bottom: 20px;
    }

    .navbar-nav {
      width: 100%;
    }

    .navbar-vertical .navbar-nav {
      margin-left: 0px !important;
      margin-right: 6px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .navbar-vertical.navbar-expand-md.fixed-left {
      padding-right: 0px !important;
    }
  }

  .side_bar_data {
    display: none;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

.navbar-vertical {
  transition: 0.5s all;
}

.navbar_options {
  li {
    margin-right: 0px;
    padding-top: 0px;
    padding-left: 20px;
  }
}

.navbar-vertical .navbar-collapse:before {
  margin: unset;
}

.mt-7 {
  margin-top: 9rem !important;
}

.breadcrumb {
  background-color: transparent;
  margin: 0;
  padding: 0 0;
}

.managers-crumb {
  color: #7b7b7b;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.manage-con {
  padding-left: 0;
  padding-right: 0;
}

.button-wrapping {
  @include buttonWrapper();
}

.comment_Bodering {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  cursor: pointer;
}

.secoundary-btn-wrapping {
  background: none !important;
  color: #012a4d !important;
  border: 1px solid #012a4d !important;
  padding: 9px 23px !important;
  box-shadow: none !important;
}

.secoundary-btn-wrapping:hover {
  background-color: #012a4d !important;
  color: #ffffff !important;
  font-size: 14px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  min-width: 95px;
}

.button-wrapping:hover {
  background-color: #0a3f6b !important;
}

.reset-btn {
  padding: 10px 19px;
  font-size: 15px;
  max-height: 45px;
  box-shadow: unset !important;
  margin-right: 0px !important;
  // width: 100%;
}

.flexy-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.change-reset-pass {
  min-width: 89px !important;
  padding: 10px 6px !important;
  max-height: 45px;
}

.reset-prop label {
  font-size: 15px !important;
}

.header-div {
  margin: 1.3rem 0 1.8rem 0;
}

.card .table td,
.card .table th {
  padding-left: 2rem;
}

.card .table th {
  font-family: "Poppins", sans-serif;
  font-weight: 600 !important;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  color: #374957;
  font-size: 0.813rem !important;
  background: #f7f8fb !important;
}

.request-flex {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.req_form_wrap {
  width: 44%;
}

.req_form_wrap_first {
  width: 53%;
}

.request-flow-card {
  max-height: 675px;
}

.request-input {
  border-bottom: 1px solid #e1e1e1 !important;
  box-shadow: none !important;
  border: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.new-border-request {
  border-bottom: 1px solid #e1e1e1 !important;
  border-right: 0;
  border-top: 0;
  border-radius: 0;
  border-left: 0;
}

.pro-wrap {
  font-size: $primaryFontSize;
}

.bank-text {
  color: #c8bfbf !important;
}

.edit-pro-prop {
  min-width: 102px !important;
  padding: 6px 15px !important;
}

.min-width-drop {
  min-width: 8rem !important;
}

.fa-info-circle {
  font-size: 22px;
  cursor: pointer;
}

.inherit-request-flex {
  flex-direction: column;
}

.form-req {
  display: flex;
  width: 100%;
}

.request-width {
  width: 100%;
}

.request-line {
  color: $tertiaryColor;
  font-size: $primaryFontSize;
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.request-inactive-line {
  color: #c4c4c4;
  font-size: $primaryFontSize;
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.request-high {
  width: 100%;
}

.request-flexy-flex {
  display: flex;
  justify-content: space-between;
}

.table-flush tbody tr:first-child td {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  padding-top: 1rem;
}

.card .table td {
  padding: 1.25rem 1.5rem 1.25rem 2rem;
  font-size: 14px;
  color: #212529;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.form-control-label-ameni {
  color: $tertiaryColor;
  font-size: 19px;
  margin-bottom: 0;
  margin-top: 0.5rem;
  margin-left: 1rem;
  font-size: 20px;
}

.equal_header_padding {
  padding-top: 1rem !important;
}

.dashboard_design_padding {
  padding-top: 0rem !important;
  padding-bottom: 1.8rem !important;
}

input[type="checkbox"]:checked {
  background: rgba(0, 0, 0, 0);
}

.col-edit {
  max-width: 8.33333% !important;
}

.edit-prop {
  min-width: 102px !important;
  padding: 6px 6px !important;
}

.change-profile-pass {
  min-width: 78px !important;
  padding: 7px 6px !important;
}

.change-profile-pass .profile-cancel {
  font-size: 15px !important;
  margin-bottom: unset !important;
  margin-top: unset !important;
}

.new-date-in {
  border-top: 1px solid $tertiaryColor !important;
  border-bottom: 1px solid $tertiaryColor !important;
  border-left: 1px solid $tertiaryColor !important;
  border-radius: 5px 0px 0px 5px !important;
}

.notice-group {
  margin-right: 0 !important;
  border-top: 1px solid $tertiaryColor !important;
  border-bottom: 1px solid $tertiaryColor !important;
  border-right: 1px solid $tertiaryColor !important;
  border-radius: 0px 5px 5px 0px !important;
  background: rgba(7, 126, 139, 0.1);
}

.modal-dialog-notice {
  max-width: 500px;
}

.modal-footer-notice {
  padding-top: 0;
}

.nav-notice {
  padding: 0 !important;
}

.label-accept {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.label-accept label {
  font-size: $secondayFontSize !important;
}

.spinner-border {
  width: 5rem;
  height: 5rem;
  color: white;
}

.spin-loading-profile-wait .spinner-border {
  width: 5rem;
  height: 5rem;
  color: $primaryColor !important;
}

.spin-loading .spinner-border {
  color: $primaryColor !important;
}

.spin-loading1 .spinner-border {
  color: $primaryColor !important;
}

.spin-loading2 .spinner-border {
  color: #ffffff !important;
}

.spin-loading3 .spinner-border {
  color: $primaryColor !important;
}

.spin-loading-none .spinner-border {
  color: $primaryColor !important;
}

.spin-loading-save .spinner-border {
  width: 1.5rem;
  height: 1.5rem;
  color: #ffffff !important;
}

.width-on-load {
  min-width: 143px !important;
}

.up-prop-btn {
  min-width: 96px !important;
}

.modal-row .modal-content {
  min-height: 182px;
  @include flexCenter(center, center);
}

.width-invite {
  width: 7% !important;

  img {
    width: 73% !important;
  }
}

.bank-body {
  @include flexCenter(center, center);
}

.notice-date .react-datepicker-wrapper {
  width: 100% !important;
}

.change-profile-button {
  padding: 8px 23px;
  font-size: $primaryFontSize;
}

.display-none {
  display: none;
}

.notify-alter {
  padding: 15px !important;
  border: 1px solid #e9e9e9;
}

.edit-prop label {
  font-size: 15px !important;
}

// Timeline

.css-ha3bif-MuiTimelineItem-root:before {
  display: none;
}

.css-1rcbby2::before {
  display: none;
}

.css-1nqcc1s {
  border-radius: 13px !important;
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.css-v6mo1y-MuiTimelineDot-root {
  border-radius: 13px !important;
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.css-1rcbby2 {
  min-height: 130px !important;
}

.css-ha3bif-MuiTimelineItem-root {
  min-height: 130px !important;
}

.pad-left-0-8 {
  padding-left: 0px !important;
}

.timeline-inherit {
  color: $tertiaryColor;
  font-family: "Poppins", sans-serif;
  font-weight: 400 !important;
}

.progress-tick-image {
  padding: 6px 5px !important;
}

.progress-image {
  padding: 18px 22px !important;
}

.progress-active-image {
  padding: 4px 4px !important;
}

.request-active-line {
  background-color: #51ca06 !important;
}

.request-red-line {
  background-color: #f83245 !important;
}

.reject-wrapping {
  background-color: unset !important;
  color: $primaryColor !important;
  border: 1px solid $primaryColor !important;
  padding: 9px 23px !important;
}

.reject-wrapping:hover {
  background-color: unset !important;
}

.request-cancel-flow-card {
  max-height: 286px;
}

.mb-0-7 {
  margin-bottom: 0.7rem !important;
}

.form-control-label {
  color: $tertiaryColor;
  font-size: 14px;
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.request-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.form-group {
  margin-bottom: 1.5rem;
}

.card-header:first-child {
  padding-left: 3rem;
}

.new-card {
  padding-left: 1.5rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
}

h3,
.h3 {
  font-size: 1.1625rem;
}

.form-control-alternative {
  border-bottom: 1px solid #e1e1e1;
  border-radius: 0;
  box-shadow: none;
  font-size: 15px;
  color: black;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.form-control-alternative:focus {
  box-shadow: none;
  border-bottom: 1px solid black;
  color: #22212f;
}

.bread-cursor {
  cursor: pointer;
}

.footer {
  display: none;
}

.border-img-park {
  object-fit: cover;
  border-radius: 8px;
  width: 75px;
  height: 75px;
  cursor: pointer;
}

.image-flexor {
  display: flex;
  margin-right: 0.938rem;
}

.row-photos {
  padding-left: 15px;
}

.title {
  width: 82%;
  white-space: unset !important;
}

.loader-btn {
  @include flexCenter(center, center);
}

.loader-btn-reverse {
  min-width: 175px !important;
}

.spinner-btn {
  .spinner-border {
    // color: #012A4D !important;
    color: #ffffff !important;
  }

  .spinner-border {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
}

.alternate_spin {
  .spinner-border {
    color: #012a4d !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
}

.send-disable {
  color: #6363637a;
  cursor: not-allowed;
}

.bg-shadowable {
  // background-color: #077e8b87 !important;
  background-color: rgba(1, 42, 77, 1) !important;
  border: none !important;
}

.reject-text-area {
  width: 100%;
  height: 112px;
  border: 1px solid #75757569;
  padding: 10px;
}

.reject-text-area::placeholder {
  color: #64626259;
}

.title-head {
  color: black;
  font-weight: 500;
  margin-bottom: 0.7rem !important;
}

.notify-alter {
  border-bottom: 0;
  padding: 32px;
  font-size: 20px;
  border-radius: 7px;
  width: 100%;
}

.text-notify {
  padding-top: 1.3rem;
  height: 140px;
}

.text-notify::placeholder {
  color: #a8a7a7;
}

.form-control-alternative::placeholder {
  padding: 0;
}

.pad-new-page {
  padding-right: unset !important;
}

.jus-space {
  justify-content: space-between;
}

.paginate-flex-box {
  display: flex;
  justify-content: center;
  width: 14%;
  margin-right: 9px;
}

.paginate-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dedede;
  border-radius: 7px;
  padding: 6px 15px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500 !important;
  margin-right: 20px;
}

.paginate-box:last-child {
  margin-right: 0px;
}

// Notice Details
.secondary-color-none {
  background: unset !important;
  padding: 1.8rem 0.8rem 0 1.8rem !important;
  padding-left: 2rem !important;
}

.notice-image-wrapper {
  flex: 0 0 14.66667% !important;
  max-width: 13.66667% !important;
}

.call-message {
  padding: 20px 0 0px;
}

.notice-det-pad {
  padding: 1.2rem 1.2rem;
}

.notice-det-pad-sec {
  padding: 2.8rem 1.2rem;
}

.font-vary {
  font-size: 1.0625rem !important;
}

.notice-body {
  padding: 0 !important;
  border-bottom: 2px solid #e8e3e373;
}

.label-notice-prop {
  font-size: 1rem !important;
  color: #636363 !important;
  font-weight: 400 !important;

  font-family: "Poppins", sans-serif;
}

.label-notice-id {
  font-size: 0.938rem !important;
  color: #393939;
  font-weight: 400 !important;

  font-family: "Poppins", sans-serif;
}

.notice-detail-para {
  color: #636363;
  font-weight: 400 !important;

  font-family: "Poppins", sans-serif;
  font-size: 17px !important;
}

.notice-flex-row {
  display: flex;
  align-items: center;

  img {
    margin-right: 1.4rem;
    width: 1.2rem;
  }

  label {
    margin-top: 0.2rem;
    color: #373d3f;

    font-family: "Poppins", sans-serif;
    font-weight: 400 !important;
  }
}

.notice-flex-row:nth-child(2) {
  margin-top: 0.7rem;
}

.card-not-pad {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.proper-img {
  max-height: 68%;
}

.row-image-notice {
  height: 120px;
}

.notice-imageprop-wrapper {
  flex: 0 0 18.66667% !important;
  max-width: 16.66667% !important;
}

.ma-r {
  margin-right: 1rem;
}

.fa-angle-left {
  font-size: 23px;
  cursor: pointer;
  color: $primaryColor;
}

.fa-angle-right {
  font-size: 23px;
  cursor: pointer;
  color: $primaryColor;
}

.disabled {
  color: #00000063;
  pointer-events: none;
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

.spin-loading {
  @include flexCenter(center, center);
  height: 100vh;
}

.spin-loading1 {
  @include flexCenter(center, center);
  height: 50vh;
}

.spin-loading2 {
  @include flexCenter(center, center);
  height: 3vh;
}

.spin-loading3 {
  @include flexCenter(center, center);
  height: 100%;
}

.spin-loading-save {
  @include flexCenter(center, center);
  height: 100%;
}

.spin-loading-none {
  @include flexCenter(center, center);
  margin-top: 20px;
}

.calenderSpin {
  position: absolute;
}

.butWid {
  width: 15rem;
  height: 3rem;
}

.image-loading {
  border: 1px solid #0000003b;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  @include flexCenter(center, center);
  color: $primaryColor !important;
}

.image-loading .spinner-border {
  color: $primaryColor;
}

.no-data {
  @include flexCenter(flex-start, center);
  font-size: 0.875rem;
  font-weight: 400;
  color: #24242491 !important;
  font-family: "Poppins", sans-serif;
  padding: 15px;
  padding-left: 2rem;
}

.no-book {
  border-bottom: 1px solid #f5f5f5;
}

.none_event {
  pointer-events: none;
}

.hamburger_nav {
  color: #7b7b7b;
  cursor: pointer;
}

// Mutiple Select

.css-1s2u09g-control {
  border-bottom: 1px solid #e1e1e1 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
}

.css-319lph-ValueContainer {
  font-size: 15px !important;

  font-family: "Poppins", sans-serif;
  font-weight: 400 !important;
  color: #22212f !important;
}

.css-1s2u09g-control {
  margin-top: 0.45rem;
}

.css-tlfecz-indicatorContainer {
  color: #22212f !important;
}

.css-1pahdxg-control {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px solid #22212f !important;
  box-shadow: unset !important;
  border-radius: 0 !important;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-g1d714-ValueContainer {
  display: flex !important;
  flex-wrap: nowrap !important;
}

.image-1oading .spinner-border {
  height: 1.2rem !important;
  width: 1.2rem !important;
}

.form-control-alternative {
  padding: 0;
}

.form-control-alternative .noti:focus {
  border-bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
}

.select-notify {
  width: 20%;
  background: white;
  height: 64px;
  padding: 13px;
  color: #a8a7a7;
  padding-left: 2rem;
}

.btn-exceed {
  max-width: 10.33333% !important;
}

.filter-wrap {
  background: transparent;
  color: $primaryColor;
  font-weight: 700;
  min-width: 113px;
  @include flexCenter(center, center);
  padding: 10px 10px;
  border-radius: 6px;
  cursor: pointer;
}

.new-send-invite {
  border-top: 1px solid #e1e1e1 !important;
  border-bottom: 1px solid #e1e1e1 !important;
  border-left: 1px solid #e1e1e1 !important;
}

.invite-group {
  border-top: 1px solid #e1e1e1 !important;
  border-bottom: 1px solid #e1e1e1 !important;
  border-right: 1px solid #e1e1e1 !important;
}

.pad-invite {
  padding: 0.4rem 1.5rem 1.7rem;
  color: rgba(0, 0, 0, 0.5);
}

.font-24 {
  font-size: 24px;
}

.modal-invite {
  padding: 1.25rem 1.5rem 1.25rem;
}

.invite-email-in {
  border-right: 1px solid #e1e1e1 !important;
}

.copy-navigator {
  width: 92%;
  @include flexCenter(space-between, center);
  background: #fafafa;
  padding: 10px;
}

.copy-navigator p {
  color: $tertiaryColor;
}

.fade-color {
  margin-top: 0.7rem;
  color: $tertiaryColor;
}

.success-invite {
  flex-direction: column;
  @include flexCenter(center, center);
  padding: 1.8rem 1.5rem 2.5rem;
}

.label-invite {
  font-size: 15px !important;
  margin-top: 0.5rem !important;
  color: #22212f !important;
}

.filter-wrap:hover {
  background-color: transparent !important;
  color: $primaryColor;
}

.filter-wrap img {
  width: 16%;
  margin-right: 0.6rem;
  margin-top: 0.1rem;
}

.filter-wrap label {
  font-size: 17px;
  margin-bottom: 0.1rem;
  cursor: pointer;
  margin-top: 0.3rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.input-filter-toggle {
  border: none;
  height: 46px;
  font-size: $secondayFontSize;
  border-radius: 0 5px 5px 0px;
}

.change-password-btn label {
  font-size: 15px !important;
}

.form_select {
  padding: 11px 18px;
  font-size: $primaryFontSize;
  background: #f5f5f5;
  border: none;
  font-size: 14px !important;
  border-radius: 5px 0 0 5px;
  color: #374957;
  height: 100%;
}

.reset-btn:not(:disabled):not(.disabled):active {
  color: $primaryColor !important;
}

.cancelbtn:not(:disabled):not(.disabled):active {
  color: $primaryColor !important;
}

.form_drop {
  padding: 11px 23px;
  font-size: $primaryFontSize;
  background: #f5f5f5;
  border: none;
  margin-right: 4rem;
  border-radius: 5px;
  width: 13%;
  height: 48%;
}

.min-load {
  min-width: 176px;
  background-color: $primaryColor !important;
  padding: 10px 23px;
  border: none !important;
}

.spinner-cut {
  border: unset !important;
}

// .form_drop,
// .form_select {
//   -webkit-appearance: none;
//   appearance: none;
// }
.notification-label {
  font-size: 20px !important;
  color: #434343 !important;
}

.select-notification {
  display: flex;
  flex-direction: column;
}

select.form_drop,
select.form_select {
  background-position: calc(100% - 20px) calc(1em + 5px),
    calc(100% - 15px) calc(1em + 5px), calc(100% - 2.5em) 0.8em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.form-control[readonly] {
  background-color: transparent;
}

.profile-img-content {
  @include flexCenter(flex-start, center);
}

// Date Picker
.react-datepicker__day--selected {
  background-color: $primaryColor !important;
}

.sphere-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
}

.label-wrap-pro {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
}

.input-group-select {
  width: 20%;
}

.input-group-select .form_select {
  width: 100%;
}

.name-profile {
  font-size: $secondayFontSize;
  font-weight: 600;
  color: #22212f;
  margin-bottom: 0;
}

.email-profile {
  font-size: $primaryFontSize;
  font-weight: 500;
  color: $tertiaryColor;
}

.pad-left-profile {
  padding-bottom: 1.4rem;
}

.pencil-bg {
  position: absolute;
  height: 23px;
  width: 23px;
  text-align: center;
  border-radius: 50%;
  right: 0;
  top: 50%;
  transform: translate(-50%, 10px);
  background-color: $primaryColor;
  cursor: pointer;

  .fa-pencil-alt {
    color: white;
    font-size: 11px;
  }
}

.sphere-image img {
  width: 90%;
  height: 90%;
  border-radius: 50%;
}

.profile-btn-img {
  position: absolute;
  opacity: 0%;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.filter_room_wrap {
  padding: 6px 10px;
}

.flex-content {
  display: flex;
  justify-content: center;
  max-width: 100%;
  flex-wrap: nowrap;
}

.align-left {
  justify-content: flex-start !important;
}

.tex-area-prop {
  line-height: 2rem;
  font-size: 15px;
}

.search-wrapper {
  font-size: $primaryFontSize;
  padding: 6px 23px;
  margin-left: 4.5rem;
}

.hide-content {
  display: none;
}

.btn-primary:not(:disabled):not(.disabled):active {
  color: $primaryColor;
  background-color: unset;
  border-color: $primaryColor;
}

.btn-primary {
  box-shadow: none !important;
}

.text-color {
  color: $primaryColor;
}

.mark_payment_date {
  height: 46px;
  font-size: 0.875rem;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid rgba(225, 225, 225, 0.4);
  // border-right: none;
  border-radius: 5px;
  // border-top-left-radius: 5px;
  // border-bottom-left-radius: 5px;
  color: #374957;
}

.filter-input-date {
  height: 46px;
  font-size: 0.875rem;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid rgba(225, 225, 225, 0.4);
  border-right: none;
  color: #374957;
}

.to_filter-input-date {
  height: 46px;
  font-size: 0.875rem;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid rgba(225, 225, 225, 0.4);
  border-left: none;
  color: #374957;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.exportDateIcon {
  border: 1px solid #f3f3f3 !important;
  height: 46px;
  text-align: center;
  padding: 16px !important;
  border-radius: 0px;
  border-left: none !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  width: 20%;
}

.scheduleDate {
  height: 46px;
  font-size: 0.875rem;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid rgba(200, 199, 199, 0.4);
  color: #374957;
  border-radius: 5px;
  margin-bottom: 20px;
}

.react-datepicker__time-list-item--selected {
  background-color: #012a4d !important;
}

.group-date {
  border: 1px solid #f5f5f5;
  height: 46px;
  text-align: center;
  padding: 16px !important;
  border-radius: 0px;
  border-left: none;
}

// .react-datepicker-wrapper {
//   width: 11.5% !important;
// }
.date-svg {
  width: 16px;
}

.dateIcon {
  position: absolute;
  right: 10px;
  width: 16px;
  top: 16px;
}

.content-flex {
  margin-left: 2.5rem !important;
}

.input-more {
  max-width: 14%;
}

.drop_more {
  margin-right: 2.5rem;

  width: 12.5%;
}

.date-merge {
  min-width: fit-content;
  padding: 10px;
  color: #374957;
  height: 46px;
  background: #f5f5f5;
  @include flexCenter(center, center);
  font-size: 0.875rem;
  min-width: 64px !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid rgba(225, 225, 225, 0.4);
  border-right: none;
}

.todate-merge {
  min-width: fit-content;
  padding: 10px;
  color: #9ba5ac;
  font-size: 13px;
  font-weight: 300;
  height: 46px;
  background: #ffffff;
  @include flexCenter(center, center);
  font-size: 0.875rem;
  // min-width: 64px !important;
  border-top: 1px solid rgba(225, 225, 225, 0.4);
  border-bottom: 1px solid rgba(225, 225, 225, 0.4);
}

.mb-remove {
  margin-bottom: 0 !important;
}

.add-filter-wrap {
  display: flex;
  margin-left: auto;
}

.progress-dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $primaryColor;
}

.progress-div {
  width: 67%;
  height: 4px;
  margin: auto;
  background-color: #c4c4c4;
  position: relative;
}

.progress-back-toggle {
  height: 100%;
  width: 0%;
  background-color: $primaryColor;
  transition: 1.5s;
}

.w_3 {
  width: 33% !important;
}

.w_6 {
  width: 66.6% !important;
}

.w_9 {
  width: 100% !important;
}

.w_3_rooms {
  width: 50%;
}

.w_6_rooms {
  width: 100%;
}

.w-5 {
  width: 50%;
}

.w-8 {
  width: 80% !important;
}

.w-42 {
  width: 42%;
}

.w-58 {
  width: 58%;
}

.w-89 {
  width: 89%;
}

.w-22 {
  width: 22%;
}

.steps-1,
.steps-2,
.steps-3,
.steps-4 {
  width: 22px;
  height: 22px;
  background-color: #c4c4c4;
  border-radius: 50%;
}

.steps-1-rooms,
.steps-2-rooms,
.steps-3-rooms,
.steps-4-rooms {
  width: 22px;
  height: 22px;
  background-color: #c4c4c4;
  border-radius: 50%;
}

.back {
  background-color: $primaryColor;
  width: 38px;
  height: 38px;
  @include flexCenter(center, center);
}

.img-abs-prop {
  width: 51%;
}

.pad-r-1-5 {
  padding-right: 1.5rem !important;
}

.pad-r-3 {
  padding-right: 3rem !important;
}

.profile-body {
  padding-top: 0;
  padding-bottom: 0;
}

.img-black {
  background-color: $primaryColor;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;

  @include flexCenter(center, center);
  transition: 1.6s;
}

.image_one {
  left: 0;
  transform: translate(-50%, -41%);
}

.image_two {
  left: 33.3%;
  transform: translate(-50%, -41%);
}

.image_three {
  left: 66.6%;
  transform: translate(-50%, -41%);
}

.image_four {
  left: 100%;
  transform: translate(-50%, -41%);
}

.image_one_rooms {
  left: 0;
  transform: translate(-50%, -41%);
}

.image_two_rooms {
  left: 50%;
  transform: translate(-50%, -41%);
}

.image_three_rooms {
  left: 100%;
  transform: translate(-50%, -41%);
}

.img-abs {
  width: 61%;
}

.steps-1 {
  position: absolute;
  left: 0;
  transform: translate(-50%, -41%);
}

.steps-1-rooms {
  position: absolute;
  left: 0;
  transform: translate(-50%, -41%);
}

.steps-2-rooms {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -41%);
}

.steps-3-rooms {
  position: absolute;
  left: 100%;
  transform: translate(-50%, -41%);
}

.steps-2 {
  position: absolute;
  left: 33.3%;
  transform: translate(-50%, -41%);
}

.steps-3 {
  position: absolute;
  left: 66.6%;
  transform: translate(-50%, -41%);
}

.steps-4 {
  position: absolute;
  left: 100%;
  transform: translate(-50%, -41%);
}

.main-content .container-sm,
.main-content .container-md,
.main-content .container-lg,
.main-content .container-xl {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.form-check-input {
  position: absolute;
  margin-top: 0.5rem;
  margin-left: -1.9rem;
  width: 20px;
  height: 20px;
}

.delete-icon {
  width: 9%;
  cursor: pointer;
}

.edit-icon {
  margin-right: 3rem;
  margin-top: 0.2rem;
  width: 15%;
  cursor: pointer;
}

.dashed-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%2333333345' stroke-width='5' stroke-dasharray='8%2c 18' stroke-dashoffset='14' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 10px;
  height: 151px;
  @include flexCenter(center, center);
  flex-direction: column;
  cursor: pointer;
}

.img-adding {
  width: 67%;
}

.modal-title {
  color: #22212f;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.dashed-border .label-wrap {
  font-size: 22px;
  color: #c4c4c4;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  cursor: pointer;
}

.label-wrap label {
  cursor: pointer;
  font-size: $primaryFontSize;
  margin-right: 14px;
}

.card-header {
  display: block !important;
  padding: 0.8rem 1.5rem !important;
  background: #f5f5f5;

  h3 {
    font-size: 1rem !important;
    color: #374957 !important;
  }
}

.chart .card-header {
  background-color: #fff !important;
}

.progress-all {
  padding-top: 2rem;
  padding-bottom: 0rem;
  margin-bottom: 4rem;
}

.inputing {
  max-width: 19%;
}

.mr-2-5 {
  margin-right: 2.5rem !important;
}

.mr-2r {
  margin-right: 2rem !important;
}

.button-secondary {
  background-color: unset !important;
  color: $primaryColor;
}

.button-secondary:hover {
  background-color: unset !important;
  color: $primaryColor;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #e9ecef;
}

.drop_down_container {
  box-shadow: none;
  width: 100%;
  height: 100%;
  background: transparent;
}

.form-relative {
  position: relative;
}

.loc_abs {
  position: absolute;
  width: 3%;
  right: 0;
  top: 93%;
  transform: translate(-50%, -110%);
  cursor: pointer;
}

.event_loc {
  position: absolute;
  width: 7%;
  right: 0;
  top: 88%;
  transform: translate(-50%, -110%);
  cursor: pointer;
  background: #ffffff;
  padding: 3px 8px;
}

// Map Moal
.map-modal {
  position: relative;
  width: 100% !important;
  min-height: 75vh !important;
}

.map-modal-address {
  min-height: 30px;
  padding: 0 0 0 15px;
  /* developer css */
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid grey;
}

.map-modal-address label {
  float: right;
  padding: 10px;
  width: 40px;
  font-size: $primaryFontSize;
  text-align: center;
  border-radius: 0 4px 0 0;
  margin: 0px;
}

.map-modal-address label:hover {
  cursor: pointer;
  background-color: rgb(0, 148, 148);
}

.map-modal-address h3 {
  float: left;
  padding: 10px;
  font-size: $primaryFontSize;
  margin: 0px;
}

.sm__modal {
  position: absolute !important;
  top: 60px;
  left: 15px;
  z-index: 100;
  width: 370px;
  height: 40px;
}

.sm__modal input {
  width: 290px;
  height: 100%;
  padding: 1rem;
  border-radius: 4px 0 0 4px;
  border: 1px solid grey;
  transition: 0.3s all;
}

.sm__modal input:focus {
  border-color: #2e75cf;
}

.sm__modal button {
  width: 80px;
  height: 100%;
  border: 1px solid teal;
  background-color: teal;
  color: #fff;
  border-radius: 0 4px 4px 0;
  transition: 0.3s all;
}

.sm__modal ul {
  background-color: #fff;
  list-style: none;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  max-height: 250px;
  overflow-y: scroll;
  padding-left: 0;
  cursor: pointer;
}

.sm__modal ul li {
  padding: 5px 15px;
  border-bottom: 1px solid #00000014;
  color: rgba(0, 0, 0, 0.5);
}

.sm__modal ul li:hover {
  background: rgba(7, 126, 139, 0.1);
}

.sm__modal::-webkit-scrollbar {
  width: 0px;
}

.map-marker {
  max-width: 1000px;
  min-height: 500px;
}

.map-modal {
  min-height: 60vh;
}

.button-wrapping:not(:disabled):not(.disabled):active {
  color: white;
}

.sec-btn-wrapping:not(:disabled):not(.disabled):active {
  color: $primaryColor !important;
}

// Tab Pane
.nav-tabs .nav-link.active {
  background: #ffffff;
  color: $secondayColor;
  padding: 10px 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
  border: none !important;
}

.navPad {
  padding: 10px 10px 10px 10px !important;
}

.nav-tabs {
  background: white;
}

.nav-tabs .nav-link:hover {
  border: none !important;
}

.nav-tabs .nav-link {
  color: #262626;
  padding: 10px 50px;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  cursor: pointer;
}

.nav-tabs .nav-link.active {
  border-bottom: 3px solid #012a4d !important;
}

.tab-prop-img {
  // width: 100%;
  // height: 140px;
  object-fit: cover;
  border-radius: 10px;
}

.new-send-book {
  color: $tertiaryColor;
  height: 40px;
  border-top: 1px solid #e1e1e1 !important;
  border-bottom: 1px solid #e1e1e1 !important;
  border-left: 1px solid #e1e1e1 !important;
}

.profile-book-img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
}

.flex-card-book {
  display: flex;
  justify-content: space-between;
  padding-top: 2.5rem;
}

.image-card-book {
  width: 16%;
}

.customer_details {
  .image-card-book {
    width: 14%;
  }

  .info-book {
    width: 86% !important;
  }
}

.info-book {
  width: 85%;
  height: 0;
}

.header-div-book {
  margin: 0rem 0 1.8rem 0;
}

.basic-data {
  color: #262626;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  width: 45%;
  margin-bottom: 0.9rem;
  font-size: 14px;
}

.basic-data-payment {
  color: $secondayColor;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  width: 36%;
}

.book-row-basic {
  width: 100%;
}

.prof-book-name {
  color: $secondayColor;
}

.basic-info-name {
  color: $tertiaryColor;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.basic-info-name-payment {
  color: $tertiaryColor;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.pad-book-phone {
  padding-top: 0.7rem;
}

.content-pad-book {
  padding-left: 2rem !important;
}

.book-name-font {
  font-size: 16px !important;
}

.font-book {
  font-size: 14px !important;
}

.new-card-book {
  padding-left: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.booking_card h3 {
  font-size: 1rem;
}

.wrap-card-book {
  padding: 42px 45px;
  max-width: fit-content;
  border: 1px solid #dedede;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.designation-book {
  color: #636363;
  font-size: 17px;
}

.name-book {
  font-size: 1rem;
}

.image-tab-wrap {
  display: flex;
}

.prop-tab-content {
  height: 85%;
  padding: 0rem 1.5rem;
}

.add-doc {
  color: $primaryColor;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.add-Doc-img {
  width: 1rem;
  margin-bottom: 0.2rem;
}

.check-date-flex {
  display: flex;
  width: 70%;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
}

.check-separate-col {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.check-book-label {
  width: inherit;
  color: $secondayColor;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.book-pad {
  padding: 1rem;
}

.back-unset {
  background-color: unset !important;
  height: 40px;
}

.back-unset1 {
  background-color: unset !important;
  height: 50px;
  border: 1px solid #f0f0f0 !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: none !important;
}

.login-flexing,
.forgot-trans {
  flex: 0 0 58.66667%;
  max-width: 58.66667%;
  transition: all 0.5s;
}

.line-2 {
  line-height: 1.7rem;
}

.notification-schedule-info {
  display: flex;
  width: 42%;
  justify-content: space-between;
}

.additional-message,
.additional-message-info {
  padding: 8px 12px;
  border: 10px;
  border-radius: 5px;

  label {
    font-family: "Poppins", sans-serif;
    font-weight: 500 !important;
    font-size: 13px;
  }
}

.additional-message {
  // background-color: rgba(7, 126, 139, 0.15);
  background-color: #e5e9ed;

  label {
    color: $primaryColor;
  }
}

.additional-message-info {
  background-color: #f4f4f4;

  label {
    color: #7b7b7b;
  }
}

.mt-0-8 {
  margin-top: 0.9rem;
}

.mt--1-2 {
  margin-top: 1.5rem !important;
}

.term-color {
  color: $primaryColor;
  font-weight: 600;
  cursor: pointer;
}

.email-new-in {
  padding: 1.025rem 0.65rem;
  font-size: 15px;
  color: #22212f !important;
  border-top: 1px solid #e1e1e1 !important;
  border-bottom: 1px solid #e1e1e1 !important;
  border-left: 1px solid #e1e1e1 !important;
}

.form-input-update {
  padding: 1.025rem 0.65rem;
  font-size: 14px;
  color: #22212f !important;
  border: 1px solid #cad1d7 !important;
  border-radius: 5px !important;
}

.new-border {
  border: 1px solid #e1e1e1 !important;
}

.signup-btn {
  width: 30%;
  padding: 10px 18px;
  font-size: 1.125rem !important;
  max-width: 27%;
  min-width: 25%;
}

.pad-left-right-2 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.label-in {
  color: #22212f !important;
  font-weight: 400 !important;

  font-family: "Poppins", sans-serif;
}

.mt-4-5 {
  margin-top: 1.5rem;
}

.login-signup {
  flex: 0 0 96.66667% !important;
  max-width: 99.66667% !important;
  transition: all 0.5s;
}

.signup-flexy {
  display: flex;
  justify-content: space-between;
}

.inherit-flexy {
  width: 47.5%;
}

.signup-btn {
  width: 30%;
  padding: 10px 18px;
  font-size: 1.125rem !important;
}

.logo-sign-image {
  width: 25%;
}

.mb-term {
  margin-top: 0.6rem;
  padding-left: 1rem;
}

.terms-pad {
  padding-left: 0.8rem;
}

.term-label {
  font-size: 13px;
  margin-top: 0.7rem;

  font-family: "Poppins", sans-serif;
  color: rgba(107, 105, 105, 0.75);
}

.term-in {
  height: 18px;
  margin-top: 0.64rem;
}

.mb-0-7 {
  margin-bottom: 0.7rem !important;
}

.signup-nav {
  padding: 2.5rem 33px 20px;
  @include flexCenter(space-between, center);
}

.signin-small {
  font-size: 22px !important;
  font-weight: 500;
  color: black;
  font-family: "Poppins", sans-serif;
}

.flex-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.new-input-login {
  box-shadow: none;
}

.new-login-in {
  border: 1px solid #e1e1e1;
}

.checking-in > input[type="checkbox"] {
  display: none;
}

.checking-in > input[type="checkbox"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  border-radius: 10%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
}

.checking-in > input[type="checkbox"]:checked + *::before {
  content: "✓";
  color: white;
  text-align: center;
  background: teal;
  border-color: teal;
}

.checking-in > input[type="checkbox"]:checked + * {
  color: teal;
}

.mb_40 h2 {
  padding-bottom: 1.25rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.563rem;
  font-weight: 500;
  color: $primaryColor;
  margin: 0;
}

.mb_40 h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: $primaryColor;
  margin: 1rem 0;
}

.mb_40 p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: $tertiaryColor;
  line-height: 2.188rem;
  font-size: 1.125rem;
}

.mb_40 li {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: $tertiaryColor;
  line-height: 2.188rem;
  font-size: 1.125rem;
}

.status_div_invite {
  display: flex;

  p {
    text-transform: capitalize;
  }

  .status_div_invite p:first-child {
    margin-right: 40px;
  }
}

.main_container_terms {
  max-width: 1440px;
  margin: 0rem auto;
  padding: 0px 85px;
}

.main-bg-content {
  background-color: white !important;
  width: 100vw;
}

.terms-design {
  display: flex;
  justify-content: center;
}

.terms-logo {
  padding: 33px 0;

  img {
    width: 13rem;
    cursor: pointer;
  }
}


// Toast
.top-right {
  top: 12px;
  right: 53%;
  transform: translateX(32%);
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.top-center {
  top: 12px;
  right: 50%;
  transform: translateX(50%);
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.notification {
  position: unset !important;
}

.bottom-right {
  bottom: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.top-left {
  top: 12px;
  left: 12px;
  animation: animate_div 0.6s 1;
  transition: 3s all;
}

.bottom-left-toast {
  bottom: 12px !important;
  left: 1%;
  animation: animate_div 0.6s 1;
  transition: 3s all;
}

.notification-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 2000;
  animation: animate_div 0.6s 1;
  transition: 3s all;
}

.toast {
  display: flex;
  padding: 20px 25px !important;
  min-width: 345px !important;
  max-width: unset !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 5px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  animation: animate_div 0.6s 1;
  transition: 3s all;
  font-size: 11px !important;
}

@keyframes animate_div {
  0% {
    top: -100%;
  }

  100% {
    top: 12px;
  }
}

.closeToast-img {
  margin-left: 2rem;
  cursor: pointer;
}

.notification-title,
.notification-message {
  color: #434343;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.toast-flex {
  display: flex;
  margin-left: 1rem;
}

.ml-7 {
  margin-left: 7px;
}

.toast-img {
  width: 1.5rem;
}

.document-add-date {
  border-bottom: 1px solid #e1e1e1 !important;
  border-top: unset !important;
  border-left: unset !important;
  border-radius: 0 !important;
  padding-left: 0;
}

.new_pad-invite {
  padding-bottom: 1.4rem !important;
}

.doc-add-group {
  border-bottom: 1px solid #e1e1e1 !important;
  border-top: unset !important;
  border-right: unset !important;
  border-radius: 0 !important;
  background-color: unset !important;
}

.tertiary-circle {
  color: #c2c2c2;
  font-size: 17px !important;
}

.radio-document-flex {
  display: flex;
  width: 47%;
  justify-content: space-between;
}

.input-document-flex {
  display: flex;
  align-items: center;
}

.yes-no-toggle {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.mail_template-card {
  padding-top: 1.8rem !important;
  padding-bottom: 4.2rem !important;
  padding-left: 4.3rem !important;
}

.mail-temp-back {
  background-color: unset !important;
}

.remove-form {
  cursor: pointer;
  width: 1.3rem;
}

.task_name {
  color: $secondayColor;
}

.form-fields {
  cursor: move;
  width: 70%;
  position: absolute;
  border: 1px solid #00000024;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  color: $tertiaryColor;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  cursor: pointer;
  z-index: 999;
}

.temp-card {
  width: 26%;
  height: 55%;
}

.flexing-template {
  display: flex;
  justify-content: space-between;
}

.new-form {
  border: 1px solid #00000024;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 12px;
  color: $tertiaryColor;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  background-color: #75757526;
  position: relative;
}

.new-form_host {
  position: relative;
  border: 1px solid #012a4d;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 12px;
  color: #012a4d !important;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  background-color: #ddf4f7 !important;
}

.emptdiv {
  position: absolute;
  right: 0px;
  height: 100%;
  width: 5%;
  top: 0px;
  background: rgb(192, 190, 190);
}

.template-container {
  height: 100%;
  width: 60%;
  z-index: 2;
}

.template-container_preview {
  height: 100%;
  width: 100%;
  z-index: 2;
}

.backcolor {
  background-color: #fafafa;
}

.row-drag .marginTop-drag:nth-child(2),
.row-drag .marginTop-drag:nth-child(3) {
  margin-top: 3.5rem;
}

.modal-preview {
  max-width: 910px !important;
}

.mr-doc {
  margin-right: 1.4rem !important;
}

.object-fit {
  object-fit: contain;
}

.mgrpdf__wrapper {
  position: relative;
}

.add-pdf {
  border: 1px dashed #c4c4c4;
  border-radius: 10px;
  width: 7.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.7rem;
  position: relative;
  cursor: pointer;
}

.pdf-add-img {
  width: 2rem;
}

.c4-add {
  color: #c4c4c4;
  font-size: 15px;
}

.pdf-add-input {
  cursor: pointer !important;
}

.shadow,
.card-profile-image img {
  box-shadow: none !important;
}

.steps_2_pur {
  left: 99.3% !important;
}

.progress-purchase {
  width: 25% !important;
}

.purchase_two {
  left: 99.3% !important;
}

.p_3 {
  width: 99.3% !important;
}

// Toggle SideBar
.toggle-sidebar,
.toggle-sidebar-width {
  position: absolute;
  height: 100vh;
  right: 0;
  top: 0;
  transition: 0.6s all;
  z-index: 99;
}

.toggle-sidebar {
  width: 0;
  position: absolute !important;
  height: 100vh;
  right: 0;
  top: 0;
  transition: 0.5s all;
  z-index: 999;
}

.modal-backdrop {
  z-index: 0 !important;
}

.opac-div-initial {
  width: unset;
  opacity: 0;
  background-color: transparent;
}

.opac-div {
  position: fixed;
  left: 0;
  height: 100%;
  width: 100vw;
  opacity: 0.6;
  top: 0;
  z-index: 99;
  background-color: #000000;
}

.opac-div-sidebar {
  display: none;
}

.toggle-sidebar-width {
  width: 27vw;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 0;
  height: 100% !important;
  position: fixed !important;
}

.responsiveSideBarToggle {
  display: none;
}

.invoice-sidebar {
  width: 700px;
  box-shadow: -2px 1px 5px rgba(0, 0, 0, 0.07);
  border-radius: 0;
  right: 0;
  top: 0;
  transition: 0.6s all;
  z-index: 99;
  height: 100% !important;
  position: fixed !important;
  overflow: scroll;
}

.payoutSideWidth {
  overflow: auto;
  width: var(--width);
}

.BookingOverFlow {
  // overflow-x: none;
  // overflow: auto;
  height: calc(100% - 30%);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the thumb (draggable part) */
    border-radius: 6px;
    /* Round the edges of the thumb */
  }
}

.exportSideBar {
  width: 450px;
  box-shadow: -2px 1px 5px rgba(0, 0, 0, 0.07);
  border-radius: 0;
  right: 0;
  top: 0;
  transition: 0.6s all;
  z-index: 99;
  height: 100% !important;
  position: fixed !important;
  overflow: scroll;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.eventSideBar {
  width: 450px;
  box-shadow: -2px 1px 5px rgba(0, 0, 0, 0.07);
  border-radius: 0;
  right: 0;
  top: 0;
  transition: 0.6s all;
  z-index: 90;
  height: 100% !important;
  position: fixed !important;
  overflow: scroll;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.eventSideBar::-webkit-scrollbar {
  display: none;
}

.exportSideBar::-webkit-scrollbar {
  display: none;
}

.scrollDiv {
  overflow-y: scroll;
}

.scrollDiv::-webkit-scrollbar {
  display: none;
}

.sec-btn-wrapping {
  background-color: transparent !important;
  color: $primaryColor;
  font-weight: 600;
}

.submit-side-tab {
  margin: unset !important;
  padding-right: 30px;
  padding-left: 30px;
  justify-content: flex-end;
  width: 100%;
  margin-top: 70px !important;
}

.sec-btn-wrapping:hover {
  background-color: transparent !important;
  color: $primaryColor;
  font-weight: 600;
}

.display-content {
  display: block;
  height: 100%;
}

.maintain-canvas {
  background-color: #f5f5f5;
}

// new host design
.big_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.navbar-vertical {
  width: 16% !important;
  z-index: 9 !important;
}

.main-content {
  margin-left: 0px !important;
  width: 84%;
  transition: all 0.5s;
}

.main_content_login {
  width: 100%;
  transition: all 0.5s;
  margin-left: 0px !important;
}

.main-content .navbar-top {
  right: 0;
}

.vertical.navbar-expand-md.fixed-left + .main-content {
  transition: 0.5s all;
}

.container_fixed_div {
  position: fixed !important;
  left: 0px;
  top: 0px;
  width: inherit;
  overflow: visible !important;
  height: 100%;
  // padding-bottom: 50px;
}

.container_fixed_div::-webkit-scrollbar {
  display: none;
}

.genaral_div_padding {
  padding: 0px 1.8rem;
}

.breadcrumb {
  font-size: 15px !important;
  color: #7b7b7b;
  font-size: 15px !important;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.breadcrumb-item.active {
  color: #7b7b7b;
}

.card_with_paginator {
  border-radius: 0px;
  box-shadow: none !important;
  border: 1px solid #f5f5f5 !important;
  border-radius: 5px 5px 0px 0px !important;
  border-bottom: none !important;
}

.btopNone {
  border-top: none !important;
}

.tdColumn {
  padding: 20px 0px 20px 40px !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tdColumn_18 {
  width: 180px;
  max-width: 180px;
}
.tdColumn_20 {
  width: 200px;
  max-width: 200px;
}
.tdColumn_23 {
  width: 235px;
  max-width: 235px;
}
.tdColumn_30 {
  width: 300px;
  max-width: 300px;
}
.tdColumn_35 {
  width: 350px;
  max-width: 350px;
}
.tdColumn_50 {
  width: 500px;
  max-width: 500px;
}

.no_data_card {
  border: 1px solid #f5f5f5 !important;
  box-shadow: none !important;
}

.paginator_box {
  border: 1px solid rgba(225, 225, 225, 0.4);
  border-radius: 0px 0px 5px 5px;
  display: flex;
  justify-content: flex-end;
  padding: 16px 10px;
  margin-top: 0px;
  border-top: none;
}

.bbNone {
  border-left: none !important;
  border-right: none !important;
}

.page_available {
  // color: #012A4D;
  color: #012a4d;
  margin: 0px;
  font-weight: 500;
  font-size: 0.875rem;
}

.page_unavailable {
  color: #c4c4c4;
  margin: 0px;
  font-size: 0.875rem;
  font-weight: 500;
}

.image_upload_display {
  width: 90px;
  height: 90px;
  position: relative;
}

.date_para_info {
  background: #cdcdcd;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
  padding: 2px;
  cursor: pointer;
  margin-left: 10px;

  span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
  }

  i {
    font-size: 10px;
  }
}

.date_info {
  display: flex !important;
}

.userType {
  border: 1px solid #e1e1e1;
  background: none;
  height: 45px;
  border-radius: 5px;
  font-size: 14px;
}

.userTypeInfo {
  padding: 0px;
  margin-left: 10px;
}

.point {
  cursor: pointer !important;
}

.mr-12 {
  margin-right: 12px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.padd20 {
  padding: 1.5rem 3rem;
}

.navigation_design {
  position: absolute;
  top: -10%;
  left: 50%;
  transform: translateX(-50%);
}

.mt-5r {
  margin-top: 5rem;
}

.paymentFailed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.paymentSuccess {
  display: flex;
  flex-direction: column;
  padding: 2rem 4rem;
  justify-content: center;
}

.paymentError {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.paymentTitle {
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 1rem;
}

.align_midd {
  text-align: center !important;
}

.modal-dialog-payment-success {
  max-width: 500px !important;
  max-height: 600px !important;
}

.modal-dialog-payment-failure {
  max-width: 450px !important;
  max-height: 600px !important;
}

.amount {
  color: #51ca06;
}

.w-300 {
  width: 340px;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.fw-200 {
  font-weight: 200;
}

.cl6F6F6F {
  color: #6f6f6f;
}

.cl6A6A6A {
  color: #6a6a6a;
}

.cl939393 {
  color: #939393;
}

.cl757575 {
  color: #757575 !important;
}

.cl22212F {
  color: #22212f;
}

.clA6AAB9 {
  color: #a6aab9;
}

.clBDBDBD {
  color: #bdbdbd;
}

.clB4B1B1 {
  color: #b4b1b1;
}

.mb-2r {
  margin-bottom: 2rem;
}

.paymentMethod {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment_type {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}

.base-align {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.modal-dialog-paymentMethod {
  width: 650px !important;
  max-width: 650px !important;
}

.payDim {
  color: #c7c7c7;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flexmid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payMethodHeader {
  background: #eef6f7;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.fc-b {
  color: #212121;
}

.bckColor {
  background: #fcfcfc;
}

.inPad {
  padding: 20px 0px;
}

.fc-w {
  color: #ffffff !important;
}

.inactiveRadio {
  border: 1px solid #c7c7c7 !important;
  color: #c7c7c7;
}

// .paymentMethod input[type="radio"]{
//   border: 1px solid  #c7c7c7 !important;
//   color: #c7c7c7 !important;
// }
.paynow-btn {
  padding: 5px 10px;
  color: #ffffff;
}

// .toolTip .tooltip-inner {
//   background: #077e8b !important;
// }
.signingSequence {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000 !important;
  background: #f5f5f5;
  background-clip: padding-box;
  border: 1px solid #e9eaec;
  border-radius: 0.375rem;
  box-shadow: none;
  margin-bottom: 1rem;
  height: 3.3rem;
}

.task {
  width: 100%;
  border: 1px solid #00000024;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  color: #757575;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  z-index: 999;
  font-size: 14px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.gridThreeColumns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.gridFourColumns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.gridSixColumns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.gridAuto {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  grid-gap: 12px;
}

.gridAuto65 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(65px, 1fr));
  grid-gap: 12px;
}

.gridAuto500 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(313px, 1fr));
  grid-gap: 12px;
}

.dashboardCards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.grGap16 {
  grid-gap: 16px;
}

.grGap10{
  grid-gap: 10px;
}

.grGap20 {
  grid-gap: 20px;
}

.grGap30 {
  grid-gap: 30px;
}

.input-field {
  border: none;
  border-bottom: thin solid #d5d3d3 !important;
  border-radius: 0px !important;
  padding: 0px !important;
  color: #000 !important;
  font-size: 14px;
}

.pad15 {
  padding: 15px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.w100 {
  width: 100%;
}

.shadow .form-control:disabled {
  background-color: rgb(250, 250, 250) !important;
  opacity: 0.5 !important;
}

.cardTitle {
  padding: 9px 13px;
  border-radius: 20px;
}

.bookStatus {
  padding: 8px 14px;
  border-radius: 20px;
}

.clrevenue {
  color: #439b91;
}

.bkRevenue {
  background: rgba(67, 155, 145, 0.1);
}

.clInvoice {
  color: #ffa451;
}

.bkInvoice {
  background: rgba(255, 164, 81, 0.1);
}

.clLateFee {
  color: #f7b84a;
}

.bkLateFee {
  background: rgba(247, 184, 74, 0.2);
}

.clPendingInvoice {
  color: #6d64dc;
}

.bkPendingInvoice {
  background: rgba(109, 100, 220, 0.1);
}

.clVacated {
  color: #f25138;
}

.bkVacated {
  background: rgba(242, 81, 56, 0.08);
}

.clpaid {
  color: #012a4d !important;
  // color: white !important;
}

.bgwhite {
  background-color: #ffffff !important;
}

.closeModal {
  border-radius: 4px;
  background-color: #f6f6f6;
  padding: 3px 10px;
}

.closePayment {
  border-radius: 4px;
  padding: 3px 10px;
  background-color: #e8e8e8;
}

.number_of {
  border-radius: 25px;
  background: #f8f8f8;
  padding: 0px 7px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardStyle {
  border: thin solid #f4f4f4;
  border-radius: 5px;
  transition: 1s;
  padding: 20px 18px;
  box-shadow: 0px 3px 20px rgba(96, 96, 96, 0.03);
}

.cardStyle:hover {
  transform: translateY(-3%);
}

.mb-26 {
  margin-bottom: 26px;
}

.mb-18 {
  margin-bottom: 18px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-14 {
  margin-bottom: 14px;
}

.mb-1_8rem {
  margin-bottom: 1.8rem;
}

.bshad {
  box-shadow: 0px 3px 20px rgba(96, 96, 96, 0.03);
}

.bshad .card-header {
  background-color: #fff !important;
}

.dashReq .card-header {
  background-color: #fff !important;
}

.borbotRevenue {
  border-bottom: 4px solid rgba(67, 155, 145, 0.3);
}

.borbotPaidIn {
  border-bottom: 4px solid #ffe3cb;
}

.borbotLatefee {
  border-bottom: 4px solid rgba(247, 184, 74, 0.3);
}

.borbotPendIn {
  border-bottom: 4px solid rgba(109, 100, 220, 0.3);
}

.bor-rad-3px {
  border-radius: 3px;
}

.card_amount {
  color: #000;
  font-weight: 500;
  font-size: 28px;
}

.f9 {
  font-size: 9px;
}

.f10 {
  font-size: 10px;
}

.f11 {
  font-size: 11px;
}

.f12 {
  font-size: 12px;
}

.f13 {
  font-size: 13px !important;
}

.f14 {
  font-size: 14px !important;
}

.f15 {
  font-size: 15px;
}

.f16 {
  font-size: 16px;
}

.f17 {
  font-size: 17px;
}

.f18 {
  font-size: 18px;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw500 {
  font-weight: 500 !important;
}

.br-5 {
  border-radius: 5px;
}

.mb-1r {
  margin-bottom: 1rem;
}

.viewMore {
  border: 1px solid #f2f2f2;
  border-radius: 30px;
  padding: 10px 15px;
  cursor: pointer;
}

.clf2f2f2 {
  color: #cccbcb;
}

.cl8B8B8B {
  color: #8b8b8b;
}

.viewAll {
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  padding: 5px 9px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.mr-4p {
  margin-right: 4px;
}

.mr-19 {
  margin-right: 19px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.w-30 {
  width: 30%;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

.w55 {
  width: 55%;
}

.w70 {
  width: 70%;
}

.chart {
  width: 100%;
  box-shadow: 0px 3px 20px rgba(96, 96, 96, 0.03);
  // padding: 24px;
}

.detailFlex {
  display: flex;
  justify-content: space-between;
}

.pad24 {
  padding: 10px 20px;
}

.clB0ADAD {
  color: #b0adad;
}

.cl212121 {
  color: #212121 !important;
}

.cl303030 {
  color: #303030;
}

.cl2A1E17 {
  color: #2a1e17;
}

.cl606060 {
  color: #606060;
}

.cl616161 {
  color: #616161;
}

.cl012A4D {
  color: #012a4d !important;
}

.cl909899 {
  color: #909899;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5p {
  margin-bottom: 5px;
}

.mb-7 {
  margin-bottom: 7px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-14 {
  margin-bottom: 14px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-13 {
  margin-top: 13px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-115 {
  margin-top: 115px !important;
}

.scheduleContent {
  padding: 20px;
}

.pad18 {
  padding-bottom: 18px !important;
  padding-top: 18px !important;
}

.borbotF8F8F8 {
  border-bottom: 1px solid #f8f8f8;
}

.h100 {
  height: 100% !important;
}

.disblock {
  display: block !important;
}

.pad0 {
  padding: 20px !important;
}

.new_pad10 {
  padding: 10px !important;
}
.card_header_title {
  font-weight: 500;
  color: #212121;
  font-size: 14px;
}

.pad30 {
  padding: 40px 30px;
}

.jus-center {
  justify-content: center;
}

.mt-1r {
  margin-top: 1rem;
}

.mt-4r {
  margin-top: 4rem;
}

.padt30 {
  padding-top: 30px;
}

.pieChart {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pad2520 {
  padding: 25px 20px !important;
}

.pad25 {
  padding: 25px;
}

.minh-300 {
  min-height: 400px;
}

.minh-41 {
  min-height: 41px;
}

.no_data_found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.pad50 {
  padding: 5px 0px !important;
}

.minh-500 {
  min-height: 500px;
}

.minh-600 {
  min-height: 600px;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.start-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start !important;
}

.status_box {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 14px;
}

.individual_status_box {
  background: #f0f0f0;
  border-radius: 5px;
  padding: 15px;
}

.f20 {
  font-size: 20px;
}

.mb-4p {
  margin-bottom: 4px !important;
}

.pad1_7 {
  padding: 1.7rem !important;
}

.x_close {
  background-color: #f6f6f6;
  padding: 0px 12px;
}

.w440 {
  width: 450px;
}

.clA0A6AD {
  color: #a0a6ad;
}

.confirmDelete {
  background-color: #012a4d;
  color: #ffffff;
  padding: 10px 30px;
  width: 110px;
}

.updateForm {
  background-color: #012a4d;
  color: #ffffff;
  padding: 10px 30px;
}

.checkoutSubmit {
  background-color: #012a4d;
  color: #ffffff;
  padding: 10px 30px;
}

.checkoutSubmit:hover {
  color: #fff;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-14 {
  margin-left: 14px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.w30 {
  width: 30%;
}

.w10 {
  width: 10%;
}

.w15 {
  width: 15%;
}

.w40 {
  width: 40%;
}

.w60 {
  width: 60%;
}

.w20 {
  width: 20%;
}

.w25 {
  width: 25%;
}

.w35 {
  width: 35%;
}

.w80 {
  width: 80%;
}

.selectAllAmenity {
  position: relative !important;
}

.flexTop {
  display: flex;
  justify-content: space-between;
}

.clfff {
  color: #ffffff !important;
}

.checkOutDatePick {
  height: 50px;
  font-size: 0.875rem;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #f0f0f0;
  color: #374957;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: none;
}

.scheduleModal .modal-content {
  overflow: unset !important;
}

.verticalUnset {
  vertical-align: unset !important;
}

.verticalMidAlign {
  vertical-align: middle !important;
}

.cl262626 {
  color: #262626;
}

.cl555555 {
  color: #555555;
}

.cl575757 {
  color: #575757;
}

.cl5A5858 {
  color: #5a5858;
}

.cl939393 {
  color: #939393 !important;
}

.profilePicName {
  background-color: #fbfafa;
  padding: 10px 20px;
  border-radius: 5px;
}

.bdr-book {
  border: 1px solid #f0f0f0;
  border-radius: 5px;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-32 {
  margin-left: 32px;
}

.ml-1r {
  margin-left: 1rem;
}

.mr-0 {
  margin-right: 0px !important;
}

.pad2415 {
  padding: 24px 15px;
}

.width5 {
  width: 5% !important;
  margin: 0 !important;
}

.pad24p {
  padding: 24px !important;
}

.clABABAB {
  color: #ababab !important;
}

.cl077e8b {
  color: #077e8b !important;
}

.cl000000 {
  color: #000000 !important;
}

.cl374957 {
  color: #374957;
}

.cl404040 {
  color: #404040;
}

.cladb5bd {
  color: #adb5bd;
}

.cl333333 {
  color: #333333;
}

.cl6C757D {
  color: #6c757d;
}

.cl1A1818 {
  color: #1a1818;
}

.lftbtmfixed {
  position: absolute;
  left: 32px;
  bottom: 0px;
}

.pad32 {
  padding: 20px 32px;
}

.sideBarFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.react-draggable > textarea {
  transition: auto !important;
}

.pad30p {
  padding: 30px !important;
}

.padT10 {
  padding-top: 10px !important;
}

.pad300 {
  padding: 30px 0px !important;
}

.minh_58 {
  min-height: 58px !important;
}

.padl30 {
  padding-left: 30px;
}

.padr30 {
  padding-right: 30px;
}

.pad0p {
  padding: 0px !important;
}

.ff-pop {
  font-family: "Poppins" !important;
}

.pad092 {
  padding: 0.9rem 1rem 1rem 1.2rem;
}

.pad10 {
  padding: 1rem 0rem;
}

.bortrans {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.bortran {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.pad_sideBar_collapse {
  padding: 1rem 1.2rem;
}

.trns5 {
  transition: 0.5s;
}

.subNav {
  // margin-left: 2rem;
  white-space: nowrap;
  text-transform: capitalize;
}

.subNav:hover {
  // color: $primaryColor !important;
  color: white !important;
}

.subChildActive {
  background-color: white;
  // border-top-left-radius: 4px;
  // border-bottom-left-radius: 4px;
  // border-top-right-radius: 2px;
  // border-bottom-right-radius: 2px;
  // border-right: none !important;
  color: #012a4d;
}

// .subChildActive:hover {
//   background-color: #01437c;
// }

.parentActive {
  background: #01437c !important;
}

@media only screen and (max-width: 1600) {
  .subNav {
    white-space: unset;
  }
}

.invoiceStatusView .status_select_div {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(225, 225, 225, 0.4);
  border-radius: 5px;
  background: white !important;
  color: #374957;
  font-size: 0.813rem !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-top: 1px;

  .btn-secondary {
    color: #374957;
    font-size: 0.813rem !important;
    font-weight: 400 !important;
    border: none;
  }

  .btn-secondary {
    box-shadow: none !important;
  }

  .btn-secondary:hover,
  .btn-secondary:active {
    background: white !important;
    box-shadow: none;
    // border-top: 1px solid rgba(225, 225, 225, 0.4);
  }

  .btn-secondary:focus {
    box-shadow: none !important;
  }

  .capitalize {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.downloadIcon {
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  padding: 10px;
  height: 100%;
  box-shadow: 0px 0px 5px #f3f3f3;
}

.calenderIcon {
  background: rgba(196, 196, 196, 0.2);
  border-radius: 25px;
  padding: 10px;
}

.w100 {
  width: 100% !important;
}

.padl0 {
  padding-left: 0px !important;
}

.padt1 {
  padding-top: 1px !important;
}

.marker {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  padding: 10px 7px 10px 18px;
  cursor: pointer;
  height: 100%;
  min-height: 150px;
  min-width: 100px;

  .date {
    margin-bottom: 10px;
    padding: 10px 0px 0px 5px;
  }

  .eventList {
    // align-items: center;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // color: #333333;
    // font-size: 12px;
    // margin-bottom: 10px;
    width: 100%;
    height: 100%;
  }

  .eventDetail {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0px 5px 5px;
  }

  img {
    margin-right: 5px;
  }

  .eventname {
    color: #333333 !important;
    font-weight: 400 !important;
    font-size: 12px !important;

    .time_w {
      width: 55px;
    }

    .name {
      overflow: hidden;
      // width: 160px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .more {
    padding: 5px 7px 5px 5px;
  }
}

.calender_selectedmonth {
  display: flex;
  align-items: center;
  justify-content: center;
}

.current_month_display {
  margin: 0 40px;
  font-size: 18px;
  color: #303030;
  cursor: pointer;
}

.Calender_Events {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.calender_display {
  width: 70%;
  margin-right: 16px;
}

.calender_title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  background: #fafafa;
}

.monthsDisplay {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1.5px;
  background: #f2f5f6;
  border: thin solid #f2f5f6;
}

.individual_month {
  display: flex;
  background: white;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100px;
}

.weekDays_display {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1.5px;
  background: #f2f5f6;
  border: thin solid #f2f5f6;
}

.individual_day {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  background: #fafafa;
  height: 70px;
  align-items: center;
}

.days_display {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1.5px;
  background: #f2f5f6;
  border: thin solid #f2f5f6;
}

.individual_days {
  display: flex;
  justify-content: flex-end;
  background: white;
  padding: 20px;
  cursor: pointer;
  height: 100px;
}

.events_tab {
  width: 100%;
  position: relative;
  border: thin solid #f2f5f6;
  display: flex;
  justify-content: center;
}

.events_details {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.blurback {
  opacity: 0.1;
}

.event_container {
  width: 100%;
  height: 100%;
}

.event_title {
  background: #fafafa;
  height: 55px;
  display: flex;
  padding: 0px 24px;
  align-items: center;
}

.event_description {
  padding: 5px 24px;
  border-bottom: thin solid #f4f4f4;
}

.event_description:last-child {
  border-bottom: 0px;
}

.event_description_details {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.event_head {
  margin: 10px 0px 20px 0px;
  color: #000000 !important;
}

.not_available {
  display: flex;
  justify-content: center;
  // align-items: center;
  padding: 10px 0px;
  min-height: 100px;
}

.googevent:last-child {
  border-bottom: 0px;
}

.googevent {
  padding: 15px 24px;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  border-bottom: thin solid #f4f4f4;
}

.linkDiv {
  color: #787878;
}

.linkDiv:hover {
  color: #787878;
}

.unsetAlign {
  align-items: unset !important;
}

.childBorder {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 5px;
  // background-color: #012A4D;
  background-color: #012a4d;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.subChildActive::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 0px;
  width: 5px;
  // background-color: #012A4D;
  // background-color: #ffffff;
  height: 100%;
  // border-top-left-radius: 4px;
  // border-bottom-left-radius: 4px;
}

.exportMultiSelect .css-1rhbuit-multiValue {
  background-color: #012a4d !important;
  color: #dedcdc !important;
  border-radius: 5px !important;
}

.exportMultiSelect .css-12jo7m5 {
  color: #fff;
  padding: 6px 14px;
  font-weight: 500;
  font-size: 14px;
}

.exportMultiSelect .css-1s2u09g-control {
  border: 1px solid #f3f3f3 !important;
}

.exportMultiSelect .css-g1d714-ValueContainer {
  padding: 16px !important;
  flex-wrap: wrap !important;
}

.exportMultiSelect .css-1pahdxg-control {
  border: 1px solid #f3f3f3 !important;
}

.exportMultiSelect .css-319lph-ValueContainer {
  padding: 16px !important;
}

.exportMultiSelect .css-xb97g8:hover {
  background-color: #012a4d !important;
  color: #dedcdc !important;
}

.requestImageUploader {
  border: thin dashed #d6d6d6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 70px;
  border-radius: 2px;
}

.imgFit {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.manageByFilter {
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  padding: 6px 10px;
  width: 17%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.verLine {
  border-left: 2px solid #f3f3f3;
  height: 100%;
}

.timelineIcon {
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  padding: 10px 15px;
  height: 100%;
  box-shadow: 0px 0px 5px #f3f3f3;
}

.timeLineRound {
  background-color: #d3d3d3;
  border-radius: 25px;
  width: 8px;
  height: 8px;
}

.timeLineVerLine {
  border-left: 1px solid #dad9d9;
  height: 100%;
}

.invoiceTimeline:last-child {
  .timeLineVerLine {
    border-left: 0px;
  }
}

.flexMidColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lh-12 {
  line-height: 12px !important;
}

.pad1015 {
  padding: 10px 15px !important;
}

.padl1r {
  padding-left: 1rem !important;
}

.padl2r {
  padding-left: 2.4rem !important;
}

.bnone {
  border: none !important;
}

.downloadLink {
  color: #262626;
  text-decoration: none;
}

.here:hover {
  color: #00aeef;
}

.here {
  color: #00aeef;
  text-decoration: underline;
}

.excelImport {
  background-color: #fcfcfc;
  border: 1px dashed #ebebeb;
  padding: 20px;
  border-radius: 10px;
}

.timelineReason {
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 4px;
}

.notifyHeader {
  background-color: #fff !important;
}

.notify-bell {
  border: none !important;
  box-shadow: none !important;
  background-color: #fff !important;
}

.pad1420 {
  padding: 14px 20px !important;
}

.notify_message:last-child {
  .notify-line {
    border-bottom: none;
  }
}

.notify-line {
  border-bottom: 1px solid #f7f7f7;
}

.notifyDropDown {
  top: 5px !important;
  left: -105px !important;
  box-shadow: 1px 1px 5px 0.3px rgb(219, 219, 219) !important;
}

.notifyIcon {
  position: relative;
}

.notifyCount {
  position: absolute;
  top: -15px;
  right: -11px;
  background: #ed5d51;
  border-radius: 50px;
  padding: 11px;
  width: 100%;
  color: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}

.notify_title_readed {
  color: #757575;
  font-weight: 500;
  margin-bottom: 0.3rem !important;
}

.notify_msg_readed {
  color: #757575;
  line-height: 1.7rem;
}

.notify_date_readed {
  color: #757575;
  font-size: 15px;
  font-weight: 500;
}

.sideBar_toggle_button_open {
  background: #cccccc;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  cursor: pointer;
}

.sideBar_toggle_button_close {
  background: #cccccc;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  cursor: pointer;
}

.expandArrow {
  width: 24px !important;
  height: 13px !important;
}

.expandArrowFull {
  width: 20px !important;
  height: 19px !important;
}

.dashMonthFilter {
  width: 11%;
}

.logout {
  padding: 0.9rem 1rem 1rem 2.513rem;
}

.historyCard {
  padding: 18px;
  background: #fafafa;
  border-radius: 5px;
  margin-bottom: 24px;
}

.navBarDropDown {
  padding: 0px !important;
  border: 0px !important;
  box-shadow: none !important;
  background: #fff !important;

  .btn-secondary:hover,
  .btn-secondary:active {
    background: white !important;
    box-shadow: none;
  }

  .btn-secondary:focus {
    box-shadow: none !important;
    background: #fff !important;
  }
}

.navBarDropDown:hover,
.navBarDropDown:active {
  background: #fff;
  box-shadow: none;
  border-color: #ffff;
}

.navDropDown {
  position: relative;
}

.drpDownshad {
  box-shadow: 1px 1px 5px 0.3px rgb(219, 219, 219) !important;
  max-height: 300px !important;
  overflow-y: scroll;
  width: 300px !important;
  padding: 14px 10px !important;
}

.propertySearchBox {
  height: 35px;
  margin-bottom: 10px;
}

// .drpDownshad > .propNameTooltip {
//   overflow: visible !important;
// }
.drpDownshad::-webkit-scrollbar {
  display: none;
}

.navBarPropName {
  // position: relative;
  padding: 0.5rem !important;
}

.navBarPropName:hover .setDefault {
  display: block;
  font-style: italic;
  font-size: 12px;
  color: #9c9c9c;
}

.add_ellipse {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navBarPropName:hover .add_ellipse {
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.setDefault {
  display: none;
}

.defaultText {
  font-style: italic;
  font-size: 12px;
  color: $primaryColor;
}

.propNameTooltip {
  width: fit-content;
}

.geoLocation {
  position: relative;
}

.geoLocation ul {
  position: absolute;
  list-style: none;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #eee;
  max-height: 250px;
  overflow-y: scroll;
  padding: 0;
  cursor: pointer;
  z-index: 1;
  width: 100%;
}

.geoLocation ul::-webkit-scrollbar {
  display: none;
}

.geoLocation li {
  padding: 5px 15px;
  border-bottom: 1px solid #00000014;
  color: rgba(0, 0, 0, 0.5);
}

.geoLocation ul li:hover {
  background: rgba(7, 126, 139, 0.1);
}

.pad020 {
  padding: 16px 5px 24px 20px !important;
}

.propListImage {
  border-radius: 3px !important;
}

.propDefaultImg {
  border-radius: 3px;
  padding: 5px;
}

.legend {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.saleLeg {
  background: #00ce68;
}

.incomeLeg {
  background: #012a4d;
}

.responsiveSideBar {
  display: none;
}

.navBarToggleIcon {
  display: none;
}

.navBarToggleImg {
  display: block;
}

.eventTime {
  .react-datepicker__time-container {
    width: 100% !important;
  }

  .react-datepicker--time-only,
  .react-datepicker-popper {
    width: 100% !important;
  }

  .react-datepicker__time-box {
    width: 100% !important;
  }
}

.googleSync {
  div {
    display: none;
  }

  span {
    padding: 0.625rem 1.25rem !important;
    font-weight: 600 !important;
  }

  background-color: $primaryColor !important;
  color: #fff !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  font-family: "Poppins", "sans-serif" !important;
}

.googleSync:hover {
  background-color: $primaryColor !important;
  color: #fff !important;
  opacity: 1 !important;
}

.tagInputDiv {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(225, 225, 225, 0.4);
  min-height: 10px;
  border-radius: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  margin-bottom: 20px;

  input {
    border: 0px;
    font-size: 14px;
    padding-left: 4px;
  }

  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    margin: 0px !important;

    padding: 0px !important;
  }

  li {
    background: $primaryColor;
    color: #fff;
    border-radius: 7px;
    padding: 5px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  span {
    margin-right: 10px;
  }

  img {
    background: #fff;
    border-radius: 25px;
  }
}

.tagInputDivList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  margin-bottom: 20px;

  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    margin: 0px !important;

    padding: 0px !important;
  }

  li {
    background: $primaryColor;
    color: #fff;
    border-radius: 7px;
    padding: 5px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.tagInputAssinessList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0px !important;
  padding: 0px !important;

  li {
    position: relative;
    background: #f0f0f0;
    padding: 6px 10px;
    margin-right: 35px;
    margin-bottom: 10px;
  }

  li:after {
    content: "";
    width: 100%;
    height: 100%;
  }

  img {
    position: absolute;
    top: 0px;
    right: -26px;
    height: 100%;
  }
}

.calenderFilter {
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  padding: 5px 10px;
  height: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.googleEventList:hover {
  background: rgba(10, 128, 128, 0.1) !important;
  border-radius: 4px !important;
}

.settingCheckbox {
  width: 16px !important;
  height: 16px !important;
  border: 1px solid #ccc9c9 !important;
  border-radius: 4px !important;
}

.settingCheckbox::after {
  padding: 0px !important;
  width: 16px !important;
  height: 16px !important;
}

.dayLine {
  position: relative;
  border: none;
  border-bottom: 1px solid #eaeaea;
  width: 95%;
}

.dayEvent {
  background-color: #012a4d;
  padding: 12px;
  color: #fff;
  font-weight: 500;
  width: 200px;
  z-index: 1;
  min-height: 60px;
  border: 1px solid #fff;
}

.daysDisplay {
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 31px 25px;
  height: 600px;
  overflow: scroll;
  position: relative;
}

.daysDisplay::-webkit-scrollbar {
  display: none;
}

.h60 {
  height: 60px;
}

.h120 {
  height: 120px;
}

.specificPropSelect {
  .css-b62m3t-container {
    border: 1px solid #f3f3f3 !important;
    border-radius: 5px !important;
    padding: 15px 7px !important;
  }

  .css-1s2u09g-control,
  .css-1pahdxg-control,
  .css-1s2u09g-control:active,
  .css-1s2u09g-control:focus,
  .css-1s2u09g-control:visited {
    border: 0px !important;
    margin: 0px !important;
  }

  .css-1rhbuit-multiValue {
    background: #012a4d !important;
    color: #fff !important;
    border-radius: 5px !important;
    padding: 6px 10px !important;
  }

  .css-12jo7m5 {
    color: #fff !important;
  }

  .css-g1d714-ValueContainer {
    flex-wrap: wrap !important;
  }

  .css-xb97g8:hover {
    background-color: #012a4d !important;
    color: #fff !important;
  }
}

@media only screen and (max-width: 1440px) {
  .logout {
    padding: 1rem 0px 1rem 1.8rem;
  }
}

@media only screen and (max-width: 960px) {
  .responsiveSideBarToggle {
    display: block;
    width: 30vw;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 0;
    height: 100% !important;
    position: fixed !important;
    height: 100vh;
    left: 0;
    top: 0;
    transition: 0.6s all;
    z-index: 99;
  }

  .opac-div-sidebar {
    display: block;
    position: fixed;
    left: 0;
    height: 100%;
    width: 100vw;
    opacity: 0.6;
    top: 0;
    z-index: 99;
    background-color: #000000;
  }

  .navBarToggleIcon {
    display: block;
  }

  .navBarToggleImg {
    display: none;
  }

  .navbar-vertical {
    display: none !important;
  }

  // .responsiveSideBar {
  // }

  .main-content {
    width: 100%;
  }

  .dashMonthFilter {
    width: 25%;
  }

  .dashboardCards {
    grid-template-columns: 1fr 1fr;
  }

  .big_graph_container {
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }

  .left_graph {
    width: 100%;
    margin-bottom: 20px;
    height: 500px;
  }

  .right_graph {
    width: 100%;
  }

  .listing_big_container {
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }

  .listing_left_container {
    width: 100%;
    margin-bottom: 20px;
  }

  .listing_right_container {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .dashboardCards {
    grid-template-columns: 1fr;
  }
}

.padr0px {
  padding-right: 0px !important;
}

.individual_day_week {
  padding: 10px 20px;
  background: #fff;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.op5 {
  opacity: 0.5;
}

.auto-flow {
  overflow: auto;
}

.br-none {
  border-radius: 0px !important;
}

.minh-100 {
  min-height: 100px;
}

.position_u {
  position: unset !important;
}

.category-dropdown {
  border: 1px solid #cad1d7;
  border-radius: 4px;
}

.category-dropdown:hover {
  background-color: transparent !important;
  border-color: #cad1d7 !important;
  box-shadow: none !important;
}

.category-dropdown:focus {
  background-color: transparent !important;
  border-color: #cad1d7 !important;
  box-shadow: none !important;
}

.sidedrag_input {
  color: #374957 !important;
  font-weight: 400 !important;
}

.request_div {
  display: flex;
  align-items: center;
}

.request_tooltip {
  margin-left: 5px;
}

// <--- Role Management Css -->
.roles_app_block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
  border: 1px solid #cad1d7;
  padding: 20px;

  .roles_apps_name {
    background: #fafafa;
    padding: 9px 25px;
    border-radius: 25px;
    color: #012a4d !important;
    cursor: pointer;
  }
}

.active_roles {
  background: #e5e9ed !important;
  // background: black !important;
}

.role_manage_checkbox {
  position: unset;
  margin: 0 !important;
}

.role_management_table {
  thead {
    tr {
      th {
        border-top: none !important;
      }
    }
  }

  td {
    border: none !important;
  }
}

.role_name_head {
  width: 12%;
}

.role_management_table tbody tr td:first-child div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #012a4d;
  font-size: 15px;
}

.role_name {
  color: #012a4d !important;
}

.apptick_img {
  margin-right: 10px;
}

.prop-remove {
  .searchWrapper {
    input {
      width: 20px !important;
    }
  }
}

.multi-select-component {
  margin-bottom: 20px !important;

  .searchWrapper {
    margin: 0 !important;
    // border: 1px solid #cad1d7;
    padding: 3px 7px !important;
    // border: none !important;

    border-radius: 5px;

    // border-bottom: 1px solid #cad1d7;
    input {
      padding: 0.625rem 0.2rem !important;
      height: calc(1.3em + 1.1rem + 1px) !important;
      font-size: 0.875rem;
      margin-top: 0 !important;
      color: #212529;
      width: 120px;
    }


    .chip {
      background: #012a4d !important;
      padding: 6px 13px !important;
      border-radius: 14px !important;
      font-size: 0.875rem;
    }
  }

  .optionListContainer {
    background: #ffffff !important;
    padding: 0 10px 10px 10px !important;
    box-shadow: 1px 1px 5px 0.3px #8c7e7e !important;

    // margin-top: 3px !important;
    .optionContainer {
      border: none !important;

      .option {
        font-size: 0.875rem !important;
        color: #212529 !important;
        border-radius: 5px !important;
        margin-bottom: 2px;
        padding: 7px 10px !important;
      }

      .option:hover {
        background: #e9ecef !important;
      }

      .highlightOption {
        // background: #b3bbc2 !important;
        background: transparent !important;
        color: #212529 !important;
      }

      .notFound {
        font-size: 0.875rem !important;
        color: #212529 !important;
      }
    }

    .optionContainer::-webkit-scrollbar {
      display: none;
    }
  }
}

.single-select-component {
  // margin-bottom: 15px !important;
  margin-bottom: 20px !important;

  .searchWrapper {
    margin: 0 !important;
    // border: 1px solid #cad1d7;
    padding: 3px 7px !important;
    border-radius: 5px;
    border-bottom: 1px solid #cad1d7;

    input {
      padding: 0.625rem 0.2rem !important;
      height: calc(1.3em + 1.1rem + 1px) !important;
      font-size: 0.875rem;
      margin-top: 0 !important;
      color: #212529;
      width: 120px;
    }

    // input::placeholder {
    //   // color: #b3bbc2 !important;
    //   // color: transparent !important;
    // }

    .chip {
      background: transparent !important;
      // background: #212529 !important;
      padding: 6px 13px !important;
      border-radius: 14px !important;
      font-size: 0.875rem;
      color: #212529 !important;
      display: flex;
      justify-content: space-between;
      align-items: center;

      i {
        display: block !important;
      }
    }

    .icon_down_dir {
      display: none !important;
    }
  }

  .optionListContainer {
    background: #ffffff !important;
    padding: 0 10px 10px 10px !important;
    box-shadow: 1px 1px 5px 0.3px #8c7e7e !important;

    // margin-top: 3px !important;
    .optionContainer {
      border: none !important;

      .option {
        font-size: 0.875rem !important;
        color: #212529 !important;
        border-radius: 5px !important;
        margin-bottom: 2px;
        padding: 7px 10px !important;
      }

      // .selected:hover {
      //   background: #e9ecef;
      // }
      .option:hover {
        background: #e9ecef !important;
      }

      // .highlight:focus {
      //   background: #e9ecef;
      // }
      .highlightOption {
        // background: #b3bbc2 !important;
        background: transparent !important;
        color: #212529 !important;
      }

      .notFound {
        font-size: 0.875rem !important;
        color: #212529 !important;
      }
    }

    .optionContainer::-webkit-scrollbar {
      display: none;
    }
  }
}

.subroles_name {
  text-transform: capitalize !important;
}

.h-100vh {
  height: 100vh !important;
}

.roles_app_view {
  display: flex;
}

.roles-icon {
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  // padding: 11px;
  background: #efefef;
  border-radius: 19px;
}



.mt-40 {
  margin-top: 40px !important;
}

.submanager_label {
  margin-bottom: 15px;
}

.submanager_edit {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 0px 10px;
  font-size: 0.875rem !important;
}

.submanager_edit:hover {
  border: 1px solid #e1e1e1 !important;
}

.submanager_edit:focus {
  border: 1px solid #e1e1e1 !important;
}

.submanager_edit::placeholder {
  font-size: 0.875rem !important;
}

.disabled-link {
  pointer-events: none;
}

.property_flexmid {
  display: inline-flex;
}


.rec_dropdown {
  height: 46px !important;
  border: 1px solid #cad1d7 !important;
}

.property_name {
  font-size: 0.875rem;
  color: #212121 !important;
  font-weight: 400;
}

.property_box {
  padding: 0 12px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stars {
  margin: 0 7px !important;
  cursor: pointer;
}

.textarea {
  height: 135px !important;
}

.just-flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-progress-all {
  padding-top: 2rem;
  padding-bottom: 0rem;
  margin-bottom: 3rem;
}

.sidebar-progress-div {
  width: 100%;
  height: 3px;
  margin: auto;
  background-color: #c4c4c4;
  position: relative;
}

.sidebar-progress-back-toggle {
  position: absolute;
  top: 0;
  height: 100%;
  width: 0%;
  background-color: $primaryColor;
  transition: 1.5s;
}

.side-img-block {
  width: 20px;
  transform: translate(0%, -50%);
  transition: 1.6s;
}

.side-img-block img {
  width: 100%;
}

.image_wt {
  width: 0px;
  transition: 1.6s;
}

.img_100 img {
  width: 80%;
}

.img_100 {
  width: 25px;
}

.step_name {
  margin-top: -5px;
}

.step_name_each {
  margin-left: 5px;
}

.card-bottom {
  border-bottom: none !important;
}

.curse_point {
  cursor: pointer;
}

.modal-dialog-recommand-contractor {
  max-width: 900px;
}

.rec-block-1 {
  padding: 0 37px;
  position: absolute;
  height: 100%;
  width: 100%;
  background: #fafafa;
  border: none !important;
  border-top-right-radius: 0%;
  border-bottom-right-radius: 0%;
}

.rec-block-2 {
  border: none !important;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
  padding-left: 10px;
  padding-right: 10px;
}

.border_status {
  border-bottom: 1px solid rgba(225, 225, 225, 0.4) !important;
}

.properties_headAlign {
  width: 15%;
  display: flex;
}

.rows_top {
  display: flex;
  justify-self: space-between;
  width: 100%;
}

.col_div {
  width: 50%;
  display: flex;
  justify-content: end;
}

.room_reset_btn_div {
  display: flex;
  width: 100%;
  justify-content: end;
}

.refer_left_poppop {
  justify-content: center;
  height: 100%;
  display: flex;
}

.refer_boxes {
  border: 1px solid #bcd2e6;
  padding: 26px;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 3px;
  cursor: pointer;
  // transition: 1s;
}



.refer_boxes_active {
  border: 1px solid #012a4d;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.refer_input {
  width: 80%;
  border: 1px solid #d9d9d9;
  border-radius: 5px 0px 0px 5px;
  height: 42px;
  border-right: none;
  color: #000000;
}

.refer_input_copy {
  border: 1px solid #d9d9d9;
  width: 20%;
  border-radius: 0px 5px 5px 0px;
  display: flex;
  justify-content: space-around;
  height: 42px;
  display: flex;
  justify-content: center;
  margin-right: 3px;
  cursor: pointer;
}

.h45 {
  height: 45px;
}

.download-btn {
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 2px solid #f3f3f3;
  border-radius: 5px;
  filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.05));
  margin-left: 17px;
  transition: 1s;
}

.download-btn:hover {
  -webkit-transform: translateY(-5%);
  transform: translateY(-5%);
}

.securevault {
  // border: 1px solid rgba(51, 51, 51, 0.1);
  padding: 10px;
  margin-left: 0px;
  margin-right: 0px;
  // border-radius: 5px;
}

.text_initial {
  text-align: initial;
}

.text-center {
  text-align: center;
}

.form-switch .form-check-input {
  width: 10em;
  height: 2em;
}

.custom-switch.custom-switch-lg {
  padding-bottom: 1rem;
  padding-left: 2.25rem;

  & .custom-control-label {
    padding-left: 0.75rem;
    padding-top: 0.15rem;

    &::before {
      border-radius: 1rem;
      height: 1.5rem;
      width: 2.5rem;
    }

    &::after {
      border-radius: 0.65rem;
      height: calc(1.5rem - 4px);
      width: calc(1.5rem - 4px);
    }
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(1rem);
  }
}

// .vaultverifymodal {
//   // max-width: 465px !important;
// }

.pin_line {
  border: 0.6px solid #d9d9d9;
  width: 100%;
}

.border-none {
  border: none !important;
}

.vault-secure-input {
  color: #333333 !important;
}

.let-space30 {
  letter-spacing: 25px !important;
}

.input-addoneye {
  position: absolute;
  right: 0;
  z-index: 99;
  margin-top: 4px;
}

.acc_verifying_modal {
  width: 470px;
  height: 610px;
  border: none;
  text-align: center;
  margin: auto;
  border-radius: 0px;
}

.vmodal_heading {
  color: #333333;
  font-size: 18px;
  font-weight: 600;
}

.vmodal_p {
  font-size: 13px;
  padding: 4px;
  font-weight: 400;
  color: #575757;
  line-height: 23px;
}

.vmodal_label {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  color: #2a1e17;
}

.text-align-left {
  text-align: left;
}

.vmodal_input {
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: 45px;
  padding: 13px;
}

.vmodal_button {
  margin-top: 5px;
  width: 100%;
  background-color: #012a4d !important;
  color: white;
  height: 47px;
  box-shadow: none;
  font-size: 13px;
  font-weight: 600;
}

.vmodal_button:hover {
  color: white;
}

.margin0 {
  margin: 0 !important;
}

.preference-contact {
  display: flex;
  justify-content: flex-start;
  align-items: self-start;
  flex-wrap: wrap;
}

.each-contacts-wrapper {
  // border: 1px solid #dedede;
  border: 1px solid #0000001a;
  border-radius: 5px;
  width: 300px;
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
}

.each-contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.each-contacts .pref-name {
  display: flex;
  padding-bottom: 5px;
  // color: #525f7f !important;
}

.each-contacts .pref-phone {
  color: #757575;
}

.pref-remove {
  padding: 10px;
  display: flex;
}

.contact-action {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-60%, 60%);
  z-index: 99;
}

.dash-card {
  padding: 15px;
}

.dash-amt {
  span {
    font-size: 22px;
    color: #374957;
    font-weight: 600;
  }
}

.dash-icon-img {
  width: 40px;
  height: 40px;
}

.dash-card1 {
  padding-left: 15px;
  padding-right: 15px;
}

.dash-card-wrapper {
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08) !important;
  border: none !important;
  border-radius: 10px;
}

.dash-row1-cards {
  min-height: 200px;
  max-height: 555px;
}

.todo-title {
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px 10px 0px 0px;
}

.skewbox {
  width: 100%;
  height: 210px;

  // background-color: #37718e;
  display: flex;
  border-radius: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.invite-image-block {
  background-color: #618aad;
  width: 35%;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invite-content {
  width: 65%;
  background-color: #ffffff;
  border-left: 46px solid #618aad;
  border-bottom: 210px solid transparent;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.recommend-img-block {
  background-color: #618aad;
  width: 43%;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recommend-content {
  width: 66%;
  background-color: #ffffff;
  border-left: 70px solid #618aad;
  border-bottom: 432px solid transparent;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.invoice-real-content {
  padding-top: 30px;
  padding-right: 20px;
}

.recommend-real-content {
  margin-top: 122px;
  margin-right: 18px;
}

.occupancy-wrapper {
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  height: 207px;
}

.occupancy-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.range-block {
  width: 320px;
  height: 17px;
  border-radius: 12px;
  background: #dcdae1;
  margin: 20px 0;
  position: relative;
}

.occupancy-status {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  background-color: #012a4d;
  border-radius: 12px;
  transition: 1s ease-in-out;
}

.invite-occupancy-wrapper {
  width: 100%;
  height: 100%;
  min-height: 437px;
  max-height: 437px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.recommend-block-wrapper {
  width: 100%;
  height: 100%;
  max-height: 437px;
}

.recommendbox {
  width: 100%;
  height: 437px;
  // background-color: #37718e;
  display: flex;
  border-radius: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.plr-15 {
  padding: 0 15px;
}

.apps-notify-box {
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: column;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  cursor: grab;
  scroll-behavior: smooth;
  justify-content: flex-start;
  // scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
}

.app-notify-wrapper {
  position: relative;
}

.app-notify-wrapper .arrow-prev {
  display: flex;
  justify-content: center;
  align-items: center;
  left: -14px;
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  transform: translateY(-50%);
  border: 1px solid #f5f5f5;
}

.app-notify-wrapper .arrow-next {
  display: flex;
  justify-content: center;
  align-items: center;
  right: -14px;
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  transform: translateY(-50%);
  border: 1px solid #f5f5f5;
}

.arrow-next:hover {
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
}

.arrow-prev:hover {
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
}

.dragging-scrollbar .apps-notify {
  pointer-events: none;
}

.apps-notify {
  min-width: 390px;
  max-width: 390px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 20px;
  overflow: auto;
  user-select: none;
  cursor: pointer;
}

.disabled-next {
  // pointer-events: none;
  display: none !important;
  // background: #c0b6b645 !important;
}

.disabled-prev {
  // pointer-events: none;
  display: none !important;
  // background: #c0b6b645 !important;
}

// .disabled-next,
// .disabled-prev .scroll_arrow {
//   // border: solid #bcbcb6;
//   // border-width: 0 3px 3px 0;
//   // display: inline-block;
//   // padding: 3px;
// }

.scroll_arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.scroll_arrow_right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.scroll_arrow_left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.padTB20 {
  padding: 20px 0 !important;
}

.todo-list {
  border-bottom: 1px solid #f8f8f8;
  padding: 20px;
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 19px;
}

.todo-list > :first-child {
  font-size: 11px;
  line-height: 20px;
  color: #575757;
  font-weight: 400;
}

.todo-list > :last-child {
  font-size: 10px;
  line-height: 10px;
  color: #b4b1b1;
  opacity: 0.5;
  font-weight: 400;
}

.todo-container {
  overflow: hidden;
  min-height: 533px;
  max-height: 533px;
}

.todo-list-container {
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  height: 100%;
  overflow-y: scroll;
}

.lh-28 {
  line-height: 28px;
}

.view_all {
  margin-right: 27px;
  border: none !important;
}

.cl525f7f {
  color: #525f7f;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.recent_request_table {
  overflow-y: scroll;
}

.linkCopyBoard {
  margin-right: 12px;
  overflow: hidden;
}

.mobRespFeild {
  margin: auto;
  padding: 50px;
  width: 430px;
  height: 935px;
  background-color: white;
}

.mobileRespContent {
  justify-self: center;
  text-align: center;
}

.mobileRespContent h2 {
  font-size: 30px;
  font-weight: 600;
  margin-top: 50px;
}

.mobileRespContent p {
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  align-items: center;
  margin-top: 20px;
}

.MobButtons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 75%;
  margin: auto;
  margin-top: 10px;
}

.MobButtons img {
  margin-right: 10px;
}

.iphoneBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 5px;
  border: none;
  background: #012a4d;
  color: white;
  margin-bottom: 13px;
  width: 249px;
  height: 50px;
  margin-top: 60px;
}

.playstoreBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 5px;
  border: none;
  color: black;
  margin-bottom: 13px;
  background-color: #dfdee1;
  width: 249px;
  height: 50px;
}

.mobileAppSuggestion {
  display: none;
}

@media (max-width: 450px) {
  .mobileAppSuggestion {
    display: block !important;
  }

  .mainRouter {
    display: none;
  }
}

.enableGreen {
  color: green !important;
}

.enableRed {
  color: red !important;
}

.mobRespIcon {
  display: flex !important;
  justify-content: space-between;
}

.mobRespLogo {
  top: 3.5%;
  left: 67%;
  width: 27%;
}

.shimDiv {
  background-color: #fffeff;
  width: 50%;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
}

.line {
  width: 100%;
  height: 17px;
  background: #f0eef0;
  margin: 20px 0;
  border-radius: 5px;
}

.shimmer {
  width: 50%;
  height: 100%;

  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 80%
  );
  animation: shimmer 1.5s linear infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-300%);
  }

  100% {
    transform: translateX(300%);
  }
}

.shimmer1 {
  color: #f3f1f3;
  background: #fffeff;
  mask: linear-gradient(120deg, #000 30%, #0005, #000 70%) right/300% 100%;
  -webkit-mask: linear-gradient(120deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  background-repeat: no-repeat;
  animation: shimmer1 1.5s infinite;
}

@keyframes shimmer1 {
  100% {
    -webkit-mask-position: left;
  }
}

.flex_sb {
  display: flex;
  justify-content: space-between;
}

.wd_5 {
  width: 5%;
}

.wd_10 {
  width: 10%;
}

.wd_20 {
  width: 20%;
}

.wd_30 {
  width: 30%;
}

.wd_40 {
  width: 40%;
}

.wd_50 {
  width: 50%;
}

.wd_60 {
  width: 60%;
}

.wd_70 {
  width: 70%;
}

.wd_80 {
  width: 80%;
}

.wd_90 {
  width: 90%;
}

.wd_100 {
  width: 100%;
}

.ml_20 {
  margin-left: 20px;
}

.ml_10 {
  margin-left: 10px;
}

.mb_0 {
  margin-bottom: 0px !important;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_50 {
  margin-bottom: 50px;
}

.mb_60 {
  margin-bottom: 60px;
}

.mt_50 {
  margin-top: 50px;
}

.pd_40 {
  padding: 40px;
}

.bd_b {
  border-bottom: 1px solid #f0eef0;
}

.apps-notifying {
  min-width: 370px;
  max-width: 390px;
  height: 80px;
  background: #fffeff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 20px;
  border: 2px solid #f3f1f3;
}

.sales_cards {
  min-width: 310px;
  max-width: 310px;
  height: 120px;
  background: #fffeff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 20px;
  margin-bottom: 18px;
  border: 2px solid #f3f1f3;
}

.bar_chart {
  width: 79%;
  background: #fffeff;
  border-radius: 10px;
  height: 33.4rem;
  padding: 20px;
  border: 2px solid #f3f1f3;
}

.boxes {
  background: #fffeff;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #f3f1f3;
}

.flex {
  display: flex;
}

.circle {
  width: 50%;
  height: 50%;
  background: #f3f1f3;
  border-radius: 50%;
  margin: auto;
  margin-top: 70px;
}

.al_center {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.h10 {
  height: 10px;
}

.h35 {
  height: 35px;
}

.grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 20px;
}

.new-cards {
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.noti_trans {
  transform: translate3d(-178px, -23px, 0px) !important;
}

.customer_input {
  width: 97%;
  padding: 9px;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  font-family: poppins;
  border: none;
  border-bottom: 1px solid grey;
  margin-top: 10px;
}

.gray {
  color: gray !important;
}

.no_active_tenants {
  margin-left: 29px;
  font-size: 12px;
  margin-top: 6px;

  .wordBreakAll {
    word-break: break-all;
  }

  .overflow {
    overflow: auto !important;
  }

  .chat_hero {
    height: calc(100vh - 180px);
    overflow: auto;

    &.noChats {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &::-webkit-scrollbar {
      width: 4px;
      /* Width of the scrollbar */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;
      /* Color of the thumb (draggable part) */
      border-radius: 6px;
      /* Round the edges of the thumb */
    }
  }

  .chatContainer {
    text-align: center;
    padding: 4px 15px;

    .timeContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #6c6b6b;
      margin: 20px 0px;

      & div:nth-child(1) {
        padding: 10px 15px;
        background-color: #faf8f8;
        border-radius: 5px;
      }
    }

    .chat_container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .serviceProvider_cotainer {
      width: 80%;
      text-align: start;
      // margin-top: 20px;
    }

    .serviceMessage {
      // position: relative;
      padding: 10px;
      background-color: #f1f7ff;
      border-radius: 0px 5px 5px 5px;

      // display: flex;
      // justify-content: space-between;
      // align-items: flex-end;
      & div:nth-child(1) {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 10px;
        color: #2201ea;
      }

      .time {
        // position: absolute;
        // right: 0;
        // bottom:1px;
        text-align: end;
        margin-left: 10px;
        font-size: 12px;
        min-width: 50px;
        margin-top: 3px;
      }
    }

    .answer_container {
      text-align: start;
      color: #ffffff;
      margin-left: 20%;
      display: flex;
      justify-content: flex-end;
    }

    .myMessage {
      // position: relative;
      padding: 10px;
      // display: flex;
      // justify-content: space-between;
      // align-items: flex-end;
      background-color: #012a4d;
      border-radius: 5px 0px 5px 5px;

      &:nth-child(1) {
        white-space: pre-wrap;
      }

      .time {
        // position: absolute;
        // right: 0;
        // bottom:1px;
        text-align: end;
        margin-left: 10px;
        margin-top: 5px;
        font-size: 12px;
        min-width: 50px;
      }
    }

    // & div:nth-child(2) {
    //   display: flex;
    //   width: 80%;

    //   text-align: start;
    //   margin-top: 20px;
    //   padding: 15px;
    //   background-color: #F1F7FF;
    //   border-radius: 0px 5px 5px 5px;

    //   // .time {
    //   //   display: table;
    //   //   margin-left: auto;
    //   // }
    // }
  }

  .chat_footer {
    padding: 20px 20px 20px 20px;

    .ft_1 {
      // position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      min-height: 50px;
      padding: 15px;
      background-color: #fafafa;
      border-radius: 8px;

      .wordBreakAll {
        word-break: break-all;
      }

      .overflow {
        overflow: auto !important;
      }

      .chat_hero {
        height: calc(100vh - 180px);
        overflow: auto;

        &.noChats {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &::-webkit-scrollbar {
          width: 4px;
          /* Width of the scrollbar */
        }

        &::-webkit-scrollbar-thumb {
          background-color: #888;
          /* Color of the thumb (draggable part) */
          border-radius: 6px;
          /* Round the edges of the thumb */
        }
      }

      .chatContainer {
        text-align: center;
        padding: 4px 15px;

        .timeContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #6c6b6b;
          margin: 20px 0px;

          & div:nth-child(1) {
            padding: 10px 15px;
            background-color: #faf8f8;
            border-radius: 5px;
          }
        }

        .chat_container {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
        }

        .serviceProvider_cotainer {
          width: 80%;
          text-align: start;
          // margin-top: 20px;
        }

        .serviceMessage {
          // position: relative;
          padding: 10px;
          background-color: #f1f7ff;
          border-radius: 0px 5px 5px 5px;

          & div:nth-child(1) {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 10px;
            color: #2201ea;
          }

          .time {
            text-align: end;
            margin-left: 10px;
            font-size: 12px;
            min-width: 50px;
            margin-top: 3px;
          }
        }

        .answer_container {
          text-align: start;
          color: #ffffff;
          margin-left: 20%;
          display: flex;
          justify-content: flex-end;
        }

        .myMessage {
          padding: 10px;
          background-color: #012a4d;
          border-radius: 5px 0px 5px 5px;

          &:nth-child(1) {
            white-space: pre-wrap;
          }

          .time {
            text-align: end;
            margin-left: 10px;
            margin-top: 5px;
            font-size: 12px;
            min-width: 50px;
          }
        }
      }

      .chat_footer {
        padding: 20px 20px 20px 20px;

        .ft_1 {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          min-height: 50px;
          padding: 15px;
          background-color: #fafafa;
          border-radius: 8px;

          & textarea {
            width: 90%;
            border-color: transparent;
            background-color: #fafafa;
            resize: none;
            overflow: hidden;
          }

          & .ft_img {
            & img {
              cursor: pointer;
            }
          }
        }
      }

      & textarea {
        width: 90%;
        // border: none;
        border-color: transparent;
        background-color: #fafafa;
        resize: none;
        overflow: hidden;
      }

      & .ft_img {
        // position: absolute;
        // top: 10px;
        // right: 10px;

        & img {
          cursor: pointer;
        }
      }
    }
  }
}

.select_cus {
  font-size: 16px;
  color: black;
  margin-top: 15px;
  font-weight: 400;
}

.scroll_bar {
  padding-right: 10px;
  overflow: scroll;
}

.scroll_bar::-webkit-scrollbar {
  display: none !important;
}

.pad-b2 {
  padding-bottom: 2rem !important;
}

.wordBreakAll {
  word-break: break-all;
}

.overflow {
  overflow: auto !important;
}

.chat_hero {
  height: calc(100vh - 180px);
  overflow: auto;

  &.noChats {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &::-webkit-scrollbar {
    width: 4px;
    /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the thumb (draggable part) */
    border-radius: 6px;
    /* Round the edges of the thumb */
  }
}

.chatContainer {
  text-align: center;
  padding: 4px 15px;

  .timeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6c6b6b;
    margin: 20px 0px;

    & div:nth-child(1) {
      padding: 10px 15px;
      background-color: #faf8f8;
      border-radius: 5px;
    }
  }

  .chat_container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .serviceProvider_cotainer {
    width: 80%;
    text-align: start;
    // margin-top: 20px;
  }

  .serviceMessage {
    // position: relative;
    padding: 10px;
    background-color: #f1f7ff;
    border-radius: 0px 5px 5px 5px;

    // display: flex;
    // justify-content: space-between;
    // align-items: flex-end;
    & .replyName {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 10px;
      color: #2201ea;
    }

    &.replyContent {
      color: "#ffffff";
    }

    .time {
      // position: absolute;
      // right: 0;
      // bottom:1px;
      text-align: end;
      margin-left: 10px;
      font-size: 12px;
      min-width: 50px;
      margin-top: 3px;
    }
  }

  .answer_container {
    text-align: start;
    color: #ffffff;
    margin-left: 20%;
    display: flex;
    justify-content: flex-end;
  }

  .myMessage {
    // position: relative;
    padding: 10px;
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-end;
    background-color: #012a4d;
    border-radius: 5px 0px 5px 5px;

    &:nth-child(1) {
      white-space: pre-wrap;
    }

    .time {
      // position: absolute;
      // right: 0;
      // bottom:1px;
      text-align: end;
      margin-left: 10px;
      margin-top: 5px;
      font-size: 12px;
      min-width: 50px;
    }
  }

  // & div:nth-child(2) {
  //   display: flex;
  //   width: 80%;

  //   text-align: start;
  //   margin-top: 20px;
  //   padding: 15px;
  //   background-color: #F1F7FF;
  //   border-radius: 0px 5px 5px 5px;

  //   // .time {
  //   //   display: table;
  //   //   margin-left: auto;
  //   // }
  // }
}

.chat_footer {
  padding: 20px 20px 20px 20px;

  .ft_1 {
    // position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    min-height: 50px;
    padding: 15px;
    background-color: #fafafa;
    border-radius: 8px;

    & textarea {
      width: 90%;
      // border: none;
      border-color: transparent;
      background-color: #fafafa;
      resize: none;
      overflow: hidden;

      &::-webkit-scrollbar {
        width: 4px;
        /* Width of the scrollbar */
      }

      &::-webkit-scrollbar-thumb {
        background-color: #888;
        /* Color of the thumb (draggable part) */
        border-radius: 6px;
        /* Round the edges of the thumb */
      }
    }

    & .ft_img {
      // position: absolute;
      // top: 10px;
      // right: 10px;

      & img {
        cursor: pointer;
      }
    }
  }
}

.top_header_requestDetail {
  display: flex;
}

.subtabs {
  flex-direction: column;
  border-right: 1px solid #f0f0f0;
  align-items: flex-start;
  padding: 24px;
}

.subtabs-navitem {
  padding: 16px 22px;
}

.subtabs-cardbody {
  display: flex;
  padding: 0 !important;
  border: 1px solid #f0f0f0;
}

.subtabs-navitem .nav-link {
  font-size: 14px;
  font-weight: 500;
  color: #404040;
  line-height: 14px;
  cursor: pointer;
  width: 200px;
}

.title-subtabs {
  padding: 20px 30px;
  font-size: 16px;
  color: #212121;
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}

.container-basicinfo {
  padding: 30px;
  font-size: 14px;
  font-weight: 500;
  color: #757575;
  display: flex;
  width: 90%;
  justify-content: space-between;
}

.Container_custDet {
  margin: 0;
  justify-content: space-between;
  align-items: flex-start;
}

.basic_information {
  color: #757575;
  width: 25%;
  margin: 0;
}

.basic_information1 {
  color: #374957;
  margin: 0;
}

.customer-img-style {
  border-radius: 4px !important;
}

.pad30px {
  padding-bottom: 30px;
}

.Aadhar-det {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.KYC_Docs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px !important;
  padding-bottom: 0 !important;
  border-bottom: 1px solid #f0f0f0;
}

.KYC_Docs1 {
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
  margin-bottom: 30px !important;
  align-items: center;
}

.KyC_Doc_images {
  display: flex;
  width: 32%;
  justify-content: flex-end;
}

.tax_slips {
  display: flex;
  width: 32%;
  margin-top: 20px;
}

.container-Kycinfo {
  font-size: 14px;
  font-weight: 500;
  color: #757575;
  width: 58%;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.basic_information2 {
  width: 25%;
  margin-bottom: 0 !important;
}

.padb20p {
  padding-bottom: 20px;
}

.activeColor {
  background-color: #e5f3ff;
  padding: 16px 22px;
  border-radius: 5px !important;
  color: #012a4d !important;
}

.imgwh {
  width: 130px;
  height: 80px;
  object-fit: cover;
}

body::-webkit-scrollbar {
  display: none;
}

.caps {
  text-transform: capitalize !important;
}

.propname_dp {
  font-size: 15px;
  font-weight: 600;
  color: #012a4d;
  line-height: 15px;
}

.PropertyDet_DP {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  margin-top: 24px;
}

.pad3035px {
  padding: 35px 30px;
}

.lh-14p {
  line-height: 14px;
}

.bs-DP {
  display: flex;
  flex-direction: row;
}

.mb-24px {
  margin-bottom: 24px;
}

.lh-15p {
  line-height: 15px;
}

.lh-16p {
  line-height: 16px;
}

.Document_Head {
  font-size: 18px !important;
  font-weight: 500;
  line-height: 18px;
  color: #212121;
}

.overflow-overlay {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  /* background: rgba(49, 49, 49, 0.1); */
}

.side-sub-menu {
  display: none;
  // position: absolute;
  // background: #ffffff;
  // z-index: 10;
  // transform: transulate(10%, 8%);
  // left: 93px;
  // border-radius: 5px;
  // box-shadow: 1px 1px 5px 0.3px #dbdbdb !important;
}

.sidebar {
  color: black !important;
  margin: 0;
  padding: 7px 0px !important;
}

.sidebarWhite {
  color: #bdb6ca !important;
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  padding: 10px 10px;
}

.navbar-vertical.navbar-expand-md .navbar-nav .sideNavBar_active {
  padding: 3px 10px !important;
  display: flex;
  justify-content: flex-start;
}

.navbar-vertical.navbar-expand-md .navbar-nav .sideNavBar_active:hover {
  // background-color: #3c2855;
  border-radius: 0px 5px 5px 0px;
  background-color: #02437c !important;

  .sidebarWhite {
    color: white !important;
  }
}

.padForSidebar {
  padding: 0px 18px !important;
}

.sideSize {
  padding: 10px 20px !important;
}

.customListContainer {
  padding: 24px;
  background-color: #f7f8fb;
}

.customListingContainer {
  border: 1px solid #e1e8f0;
  background-color: #ffffff;
  border-radius: 10px;
}

.d_flex_sb_ac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listingHeader {
  min-height: 70px;
  padding: 14px 24px;
  border-bottom: 0.5px solid #e1e8f0;
}

.containerListing_name {
  font-weight: 600;
  font-size: 16px;
  color: #212121;
}

.customContainer {
  padding: 24px;
  border: 1px solid #e1e8f0;
  background-color: #ffffff;
  border-radius: 10px;
}

.justend {
  display: flex;
  justify-content: end;
}

.main-content {
  background-color: #f7f8fb !important;
}

.mt-8 {
  margin-top: 0px !important;
}

.forDetail {
  background: white;
  margin: 15px 0px 5px 0px;
}

.sideNavContainer:hover .side-sub-menu {
  display: block;
  position: absolute;
  background: #2e1a47;
  z-index: 10;
  transform: translate(10%, 8%);
  left: 51px;
  top: -10px;
  border-radius: 0px 5px 5px 0px;
  // box-shadow: 1px 1px 5px 0.3px #dbdbdb !important;
  width: 150px;
}

.active_collapse_sidebar {
  background-color: white;
}

.active_collapse_sidebar:hover .side_popup {
  position: absolute;
  right: -11px;
  display: block;
}

// .active_parent {
//   position: absolute;
//   left: 0px;
//   top: 0px;
//   width: 5px;
//   background-color: white;
//   height: 100%;
//   border-top-right-radius: 4px;
//   border-bottom-right-radius: 4px;
// }
.active_parent_side_sub_menu {
  position: absolute;
  left: 0px;
  top: 4px;
  width: 4px;
  background-color: #dcdbdb;
  height: 85%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.padBT0 {
  padding-bottom: 0% !important;
}

.custom_btn_no_border {
  padding: 10px 23px;
  font-size: 13px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 5px;
  color: #72569c;
  background-color: transparent;
}

.asscending {
  border-radius: 2px;
  padding: 3px 5px 3px 6px;
  background: white;
  border: 1px solid #505f6b;
  cursor: pointer;
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.btn {
  font-weight: 500 !important;
}

.customSelectContainer {
  display: grid;
  grid-template-columns: 0.8fr 2fr;
  width: 400px;
}

.customSelect {
  background-color: white;

  &.ant-select-single {
    height: 45px;
  }

  .ant-select-selection-item {
    margin-left: 10%;
  }

  .ant-select-selector {
    border-right: 0px !important;
    border-color: #e1e8f0 !important;
    box-shadow: none !important;
    border-radius: 5px 0px 0px 5px;
  }

  .ant-select-selector:hover {
    box-shadow: none !important;
  }

  .ant-select-selector:focus {
    box-shadow: none !important;
  }

  .ant-select-item-option-content {
    color: red !important;
    /* Change to your desired color */
  }

  .ant-select-item-option:hover {
    background-color: #f0f0f0 !important;
    /* Change to your desired hover background color */
    color: #1890ff !important;
    /* Change to your desired hover text color */
  }
}

.input-filter-toggle {
  border: 1px solid #e1e8f0 !important;
  height: 45px;
  border-radius: 0 5px 5px 0px;
}

.select_with_input {
  min-width: 400px !important;
}

.status_select_div {
  min-width: 135px !important;
}

.flexWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  padding: 24px;
}

.documentCard {
  margin-right: 15px;
  padding: 15px 15px 10px 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  // display: flex;
  width: 32%;
  border: 1px solid #e1e8f0;
  // justify-content: space-between;
}

.justCenter {
  display: flex;
  align-items: center;
}

.radius-0 {
  border-radius: 0px !important;
}

.t79999d2d-7ec6-4076-8e4f-1097a6435480.place-top {
  height: 8px !important;
}

.noAvailable {
  font-size: 14px;
  color: gray;
  padding: 20px 0px 0px 32px;
  margin-bottom: 0px;
}

.padLeft30 {
  padding-left: 30px !important;
}

.ant-select-selector {
  border-bottom: 1px solid #e1e1e1 !important;
  border-radius: 0px !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  padding: 0px !important;
  box-shadow: none !important;
}
.indivi_customSelect {
  width: 100%;
}
.ant-select-selection-item {
  padding-right: 0px !important;
  font-size: 15px !important;
  color: black !important;
}
.imageandpdf {
  // height: 130px;
  margin-right: 10px;
  border: 1px solid gray;
  border-radius: 8px;
}
