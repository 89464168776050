.ft_family {
  font-family: "Poppins", sans-serif !important;
  /* font-family: "Jost", sans-serif !important; */
}
.p_l_r_5 {
  padding: 0px 0.3rem;
}
.pl_20 {
  padding-left: 1.125rem;
}
.sub_text_green {
  color: #012a4d !important;
}
.card_header_left_padding {
  padding-left: 2rem !important;
}
.pl_10 {
  padding-left: 0.625rem;
}
.ft_500 {
  font-weight: 500 !important;
}
.ft_400 {
  font-weight: 400 !important;
}
.ft_300 {
  font-weight: 300 !important;
}
.ft_600 {
  font-weight: 600 !important;
}
.ft_700 {
  font-weight: 700 !important;
}
.ft_white {
  color: white;
}
.mb_0 {
  margin-bottom: 0 !important;
}
.d_flex_space_align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
._m {
  margin: 0px !important;
}
.cursor_pointer {
  cursor: pointer !important;
}
.mt_0 {
  margin-top: 0px !important;
}
.mt_10 {
  margin-top: 10px !important;
}
.mb_5 {
  margin-bottom: 5px !important;
}
.mb_one_rem {
  margin-bottom: 1rem !important;
}
.mb_15 {
  margin-bottom: 0.938rem !important;
}
.mb_50 {
  margin-bottom: 3.125rem !important;
}
.mb_mt_28 {
  margin-bottom: 1.8rem !important;
}
.w_100 {
  width: 100% !important;
}
.mt_20 {
  margin-top: 1.25rem !important;
}
.mt_30 {
  margin-top: 1.875rem !important;
}
.mt_15 {
  margin-top: 0.938rem !important;
}
.mt_70 {
  margin-top: 4.375rem;
}
.mb_20 {
  margin-bottom: 1.25rem !important;
}
.mb_24 {
  margin-bottom: 1.5rem !important;
}
.mb_40 {
  margin-bottom: 2.5rem !important;
}
.ml_10 {
  margin-left: 10px !important;
}
.p_20 {
  padding: 1.25rem !important;
}
.p_0 {
  padding: 0px !important;
}
.pb_35 {
  padding-bottom: 3.188rem !important;
}
.pb_15 {
  padding-bottom: 0.938rem !important;
}
.pb_25 {
  padding-bottom: 1.563rem !important;
}
.mb_20 {
  margin-bottom: 1.25rem !important;
}
.mb_25 {
  margin-bottom: 1.563rem !important;
}
.mb_60 {
  margin-bottom: 3.75rem !important;
}
.content_restrict {
  max-height: 40px;
  overflow: hidden;
}
.mb_120 {
  margin-bottom: 7.5rem !important;
}
.mb_30 {
  margin-bottom: 1.875rem !important;
}
.line_height_35 {
  line-height: 35px;
}
.pt_10 {
  padding-top: 0.625rem !important;
}
.p_30 {
  padding: 1.875rem !important;
}

.pl_30 {
  padding-left: 1.875rem !important;
}
.p_1rem {
  padding: 1rem !important;
}
.ml_20 {
  margin-left: 1.25rem !important;
}
.mr_15 {
  margin-right: 0.938rem !important;
}
.mr_10 {
  margin-right: 0.625rem;
}
.mr_4rem {
  margin-right: 4rem !important;
}
.ft_15 {
  font-size: 0.938rem !important;
}
.ft_16 {
  font-size: 1rem !important;
}
.line_height_30 {
  line-height: 30px;
}
.ft_10 {
  font-size: 0.625rem !important;
}
.ft_18 {
  font-size: 1.125rem !important;
}
.ft_30 {
  font-size: 1.875rem !important;
}
.ft_12 {
  font-size: 0.75rem !important;
}
.ft_14 {
  font-size: 0.875rem !important;
}
.ft_17 {
  font-size: 1.063rem !important;
}
.ft_25 {
  font-size: 1.563rem !important;
}
.ft_35 {
  font-size: 2.188rem !important;
}
.ft_40 {
  font-size: 2.5rem !important;
}
.mb_10 {
  margin-bottom: 0.625rem !important;
}
.mb_14 {
  margin-bottom: 0.875rem !important;
}
.mb_16 {
  margin-bottom: 1rem !important;
}
.bold {
  font-weight: bold !important;
}
.mb_100 {
  margin-bottom: 6.25rem !important;
}
.mb_5 {
  margin-bottom: 0.313rem !important;
}
.mb_60 {
  margin-bottom: 3.75rem;
}
.mb_45 {
  margin-bottom: 2.813rem;
}
.mb_70 {
  margin-bottom: 4.375rem !important;
}
.ft_20 {
  font-size: 1.25rem !important;
}
.ft_45 {
  font-size: 2.813rem !important;
}
.ft_22 {
  font-size: 1.375rem !important;
}
.w_50 {
  width: 50%;
}
.w_45 {
  width: 45% !important;
}
.d_flex {
  display: flex !important;
}
.d_flex_align {
  display: flex !important;
  align-items: center !important;
}
.align_center {
  text-align: center !important;
}
.align_right {
  text-align: right !important;
}
.d_flex_align_justify {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}
.d_flex_space {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.d_flex_align_start {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.flex_column {
  display: flex !important ;
  flex-direction: column !important;
}
.flex_end {
  display: flex !important;
  justify-content: flex-end !important;
}
.flex_column_justify_center {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}
.capitalize {
  text-transform: capitalize !important;
}
.mr_10 {
  margin-right: 0.625rem !important;
}
.mr_0 {
  margin-right: 0px !important;
}
.mr_20 {
  margin-right: 1.25rem !important;
}
.mr_35 {
  margin-right: 2.188rem !important;
}
.mat-menu-item {
  outline: none !important;
}
.sub_content_color {
  color: #757575;
}
.button_without_border {
  border: none !important;
  outline: none !important;
  background: transparent !important;
  color: #012a4d !important;
  font-size: 15px !important;
}
.green_text {
  color: #012a4d !important;
}
.pr_0 {
  padding-right: 0px !important;
}
.no_border {
  border: none !important;
}
.input_with_icon {
  border-bottom: 1px solid #e1e1e1 !important;
}
.black {
  color: #212529 !important;
}
.status_yellow {
  color: #f7b84a !important;
}
.status_completed {
  color: #2cb67d !important;
}
.status_assinged {
  color: #008000 !important;
}
.status_inprogress {
  color: #f7b84a;
}
.status_accepted {
  color: #008000 !important;
}
.status_red {
  color: #f25138 !important;
}
.relative_div {
  position: relative;
}
.box-shadow-unset {
  box-shadow: none !important;
}

