//
// Cards
//
.card {
  border: 1px solid rgba(225, 225, 225, 0.4);
  box-shadow: none !important;
}
.white_header {
  background: white !important;
}
@import "cards/card";
@import "cards/card-profile";
@import "cards/card-animations";
@import "cards/card-stats";
